import type {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import type {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    sticky: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: 0,
      position: 'sticky !important' as 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: '#fff',
    },
    stickyWithValues: {
      paddingBottom: theme.spacing.unit * 2,
    },
  });

export type Styles = WithStyles<typeof styles>;
