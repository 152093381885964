export const toUSLocale = (
  value: string | number,
  decimals?: number,
  maximumFractionDigits?: number,
) => {
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits,
  });
};

export const isLatitude = (lat: number) => isFinite(lat) && Math.abs(lat) <= 90;
export const isLongitude = (lng: number) => isFinite(lng) && Math.abs(lng) <= 180;

export const hasValidCoordinates = (coordinates: number[]) =>
  coordinates &&
  coordinates.length > 1 &&
  isLatitude(coordinates[0]) &&
  isLongitude(coordinates[1]);
