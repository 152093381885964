import _isEmpty from 'lodash.isempty';

import {sortByProperty} from 'utils/array';
import {compose} from 'utils/functional';

import type {InitialState} from './reducers';
const applyFilters = (filters: InitialState['filters']) => (markets: InitialState['allData']) => {
  const {searchTerm, inactiveMarkets} = filters;

  const filterBySearch = (market: typeof markets[number]) => {
    return (
      !searchTerm ||
      market.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      market.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  return markets.filter((market) => filterBySearch(market) && market.active === !inactiveMarkets);
};

const customSortByProperty = (sorting: InitialState['pagination']['sort']) => {
  const sortingField = Object.keys(sorting)[0];
  return sortByProperty(sortingField, sorting[sortingField]);
};

export const updateData = (state: InitialState) => {
  const {allData, filters, pagination} = state;

  if (_isEmpty(allData)) {
    return [];
  }

  return compose(customSortByProperty(pagination.sort), applyFilters(filters))(allData);
};
