export class URLParams {
  params: {[index: string]: string} = {};
  qs: string;

  constructor(search: string) {
    this.qs = search[0] === '?' ? search.substr(1) : search;
    this.parseQuerstring();
  }

  parseQuerstring() {
    this.qs
      .split('&')
      .filter(Boolean)
      .reduce((a, b) => {
        let [key, val] = b.split('=');
        a[key] = val;
        return a;
      }, this.params);
  }

  get(key: string) {
    return this.params[key] || null;
  }

  set(key: string, value: string) {
    this.params[key] = value;
  }

  delete(key: string) {
    delete this.params[key];
  }

  toString() {
    const result = Object.keys(this.params)
      .filter(Boolean)
      .reduce((res, key) => [...res, `${key}=${this.params[key]}`], [])
      .join('&');
    return result;
  }
}
