import React from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import ErrorIcon from '@material-ui/icons/Error';
import {LoadingSpinner} from '@onsmart/ui-kit';
import {withSnackbar} from 'notistack';
import {bindActionCreators} from 'redux';

import {actions} from 'config/redux/global/actions';

import type {CSSProperties} from 'react';
import type {Dispatch} from 'react-redux';
import type {SnackbarProps} from '@material-ui/core/Snackbar';
import type {Theme} from '@material-ui/core/styles/createMuiTheme';
import type {WithStyles} from '@material-ui/core/styles/withStyles';
import type {WithSnackbarProps} from 'notistack';
import type {RootState} from 'config/redux/rootReducer';

const snackStyles = (theme: Theme) =>
  createStyles({
    snackbar: {
      '@media (max-width: 959.95px)': {
        flexGrow: 0,
        minWidth: 288,
        maxWidth: 568,
        borderRadius: 4,
      },
    },
    progress: {
      width: 24,
      height: 24,
      borderColor: '#000',
      borderLeftColor: '#fff',
    },
  });

type Styles = WithStyles<typeof snackStyles>;
interface CustomSnackbarProps extends Styles {
  key: React.Key;
  message: React.ReactElement<any>;
  action: React.ReactElement<any>;
  onClose?: SnackbarProps['onClose'];
  onExit?: SnackbarProps['onExit'];
  onRefresh?: () => void;
  autoHideDuration?: number;
}

const refreshPage = () => {
  window.location.reload();
};

type SnackbarStackProps = MapDispatchToProps & MapStateToProps & WithSnackbarProps & Styles;

class SnackbarStack extends React.Component<SnackbarStackProps> {
  displayed: React.Key[] = [];

  storeDisplayed = (messageKey: React.Key) => {
    this.displayed = [...this.displayed, messageKey];
  };

  removeDisplayed = (messageKey: React.Key) => {
    this.displayed = this.displayed.filter((key) => messageKey !== key);
  };

  componentDidMount() {
    this.syncReduxStore();
  }

  componentDidUpdate() {
    this.syncReduxStore();
  }

  syncReduxStore = () => {
    const {messages = {}, classes} = this.props;
    Object.values(messages).forEach((message) => {
      if (message === undefined) {
        return;
      }

      if (message.dismissed) {
        this.removeMessage(message);
        return;
      }

      if (this.displayed.includes(message.key)) {
        return;
      }
      const handleCallbackAction = () => {
        message.callbackAction(this.props.connectDispatch);
      };
      const iconAction = {
        loading: <LoadingSpinner className={classes.progress} />,
        error: <ErrorIcon style={{color: 'white'}} color="inherit" />,
        refresh: <RefreshSnackbar onRefresh={refreshPage} />,
        callback: (
          <Button style={{color: 'white'}} onClick={handleCallbackAction}>
            {message.callbackLabel}
          </Button>
        ),
      }[message.action];

      this.enqueueSnackbar({
        classes,
        key: message.key,
        message: <span>{message.title}</span>,
        action: iconAction,
        onClose: (ev, reason) => this.removeMessage(message),
        onExit: () => this.removeMessage(message),
        autoHideDuration: message.autoHideDuration,
      });
      this.storeDisplayed(message.key);
    });
  };

  removeMessage = (message: RootState['app']['messages']['']) => {
    this.props.removeMessage(message);
    this.props.closeSnackbar(message.key);
    this.removeDisplayed(message.key);
  };

  enqueueSnackbar = (props: CustomSnackbarProps) => {
    this.props.enqueueSnackbar(props.message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      ContentProps: {
        classes: {
          root: props.classes.snackbar,
        },
      },
      key: props.key,
      preventDuplicate: true,
      action: props.action,
      autoHideDuration: props.autoHideDuration,
      onClose: props.onClose,
      onExit: props.onExit,
    });
  };

  render() {
    return null;
  }
}

const RefreshSnackbar = (props) => (
  <div style={styles.refreshSnackbarContainer}>
    <span style={styles.refreshSnackbarSpan} onClick={props.onRefresh}>
      REFRESH
    </span>
  </div>
);

const styles = {
  refreshSnackbarContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 7,
  } as CSSProperties,
  refreshSnackbarSpan: {
    color: 'rgb(226, 188, 255)',
    cursor: 'pointer',
    marginRight: 10,
    fontSize: 12,
    fontWeight: 'bold',
  } as CSSProperties,
  refreshSnackbarIcon: {
    width: 18,
    height: 18,
    cursor: 'pointer',
  } as CSSProperties,
  connectionSnackbarSpan: {
    display: 'flex',
    alignItems: 'center',
  } as CSSProperties,
  connectionSnackbarIcon: {
    marginRight: '0.8rem',
  } as CSSProperties,
};

const mapStateToProps = (state: RootState) => ({
  messages: state.app.messages || ({} as typeof state.app.messages),
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      showMessage: actions.showMessage,
      removeMessage: actions.removeMessage,
      connectDispatch: dispatch,
    },
    dispatch,
  );

type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export default withSnackbar(
  withStyles(snackStyles)(connect(mapStateToProps, mapDispatchToProps)(SnackbarStack)),
);
