import type {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';

import type {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    selectWithValues: {
      borderTop: 'none',
      marginTop: 0,
    },
    includeInactiveMarketsSection: {
      marginTop: 0,
      marginBottom: 0,
    },
    checkbox: {
      fontSize: 14,
    },
  });

export type Styles = WithStyles<typeof styles>;
