export const downloadCSV = (name: string, content) => {
  const a = document.createElement('a');
  const mimeType = 'application/octet-stream';
  const fileName = `${name}.csv`;

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName,
    );
  } else if (URL && 'download' in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      }),
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    (window.location as any).href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
};
