import type {History} from 'history';
import {all} from 'redux-saga/effects';

import {querySync, stringTransforms} from '../../prism/lib';
import {actions} from '../actions';

import type {QuintileDataSetStatus} from '__generated__/globalTypes';

export default function* init(history: History<unknown>) {
  yield all([
    querySync('$limit', {
      history,
      selector: ({quintileDataSets: {pagination}}) => pagination.limit,
      action: actions.setPageLimit,
      stringToValue: parseInt,
    }),
    querySync('$skip', {
      history,
      selector: ({quintileDataSets: {pagination}}) => pagination.skip,
      action: actions.setPageSkip,
      stringToValue: parseInt,
      listeners: [actions.setFilters, actions.resetFilters],
    }),
    querySync('$sort', {
      history,
      selector: ({quintileDataSets: {pagination}}) => pagination.sort,
      action: actions.setPageSort,
    }),
    querySync('searchTerm', {
      history,
      selector: ({quintileDataSets: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        searchTerm: stringTransforms.stringToValue(s),
      }),
      valueToString: (v) => stringTransforms.valueToString(v.searchTerm),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('status', {
      history,
      selector: ({quintileDataSets: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        quintileDataSetStatus: stringTransforms
          .stringToValue(s)
          .split('|') as QuintileDataSetStatus[],
      }),
      valueToString: (v) => stringTransforms.valueToString(v.quintileDataSetStatus.join('|')),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
  ]);
}
