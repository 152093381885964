import {authentication} from '@onsmart/auth-client';

import {environment} from 'config/environment';
import {RestService} from 'services/RestService';

export class FilterQueryRestService extends RestService<unknown> {
  private constructor() {
    super({
      baseUrl: environment.settings.apiUrl.queryApi,
      apiUrl: '',
      token: authentication.getToken(),
    });
  }

  static getInstance() {
    return new FilterQueryRestService();
  }

  public queryCSV(ids: string[], madFields: string[]) {
    return this.axiosInstance
      .post<any>(
        '/query',
        {
          select: {mad: madFields},
          sort: null,
          where: {mad: {must: {id: {terms: ids}}}},
        },
        {params: {format: 'csv'}},
      )
      .then((r) => r.data);
  }
}
