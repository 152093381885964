import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import {actions} from './actions';

import type {IGetAssetWithParentResponse} from '../../../../services/AssetRestService';
export const initialState = {
  data: {} as IGetAssetWithParentResponse,
  assetFormLoading: false,
};

const data = createReducer({}, initialState.data).on(actions.setData, (state, payload) => ({
  ...payload,
}));

const assetFormLoading = createReducer({}, initialState.assetFormLoading)
  .on(actions.showAssetFormLoading, () => true)
  .on(actions.hideAssetFormLoading, () => false);

export type InitialState = typeof initialState;

export default combineReducers<InitialState>({
  data,
  assetFormLoading,
});
