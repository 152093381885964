import React from 'react';
import {usePrevious} from './usePrevious';

import type {ScrollbarsType} from '@onsmart/ui-kit';

/**
 * Scroll to bottom based in a state changing
 */
export const useScrollListToBottom = <T>(state: T[]) => {
  const ref = React.useRef<{scrollbars: ScrollbarsType}>(null);
  const previousState = usePrevious(state);

  /**
   * Try to scroll to bottom when the state length is increased.
   */
  React.useEffect(() => {
    if (state?.length > previousState?.length) {
      ref.current?.scrollbars?.scrollToBottom?.(true);
    }
  }, [state, previousState]);

  return ref;
};
