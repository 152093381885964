import {SearchTermFilter} from '@onsmart/ui-kit';
import {ShowingsSortOption, ShowingStatus} from '__generated__/globalTypes';
import {createReducer} from 'redux-act';

import {actions} from './actions';

const initialState = {
  pagination: {
    skip: 0,
    limit: 50,
    sort: ShowingsSortOption.MARKET_NAME_ASC,
  },
  filters: {
    [SearchTermFilter.id]: '',
    status: [ShowingStatus.ACTIVE]
  },
};

export type Filters = typeof initialState.filters;

export type InitialState = typeof initialState;

export default createReducer<InitialState>({}, initialState)
  .on(actions.setPageSort, (state, sort) => ({
    ...state,
    pagination: {
      ...state.pagination,
      sort,
      skip: initialState.pagination.skip,
    },
  }))
  .on(actions.setPageLimit, (state, limit) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: 0,
      limit,
    },
  }))
  .on(actions.setPageSkip, (state, skip) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip,
    },
  }))
  .on(actions.resetFilters, (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    filters: initialState.filters,
  }))
  .on(actions.setFilters, (state, filters) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    filters,
  }))
  .on(actions.concatFilters, (state, filters) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters,
    },
  }));
