import React, {useCallback, useMemo, useState} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Autocomplete,
  AutocompleteSize,
  FilterActionPopper,
  MultiSelectList,
  PopperContentSection,
} from '@onsmart/ui-kit';
import equal from 'fast-deep-equal';
import {useScrollListToBottom} from 'hooks/useScrollListToBottom';
import {debounce} from 'lodash';

import {LocationType} from '../../../../../__generated__/globalTypes';
import {useOpen} from '../../../../../hooks/useOpen';
import {useFilterOptions, useLocationSuggestions} from '../hooks';
import {formatLocationTypeLabel} from '../utils/locationTypeFormatter';
import {styles} from './styles';

import type {LocationFilterProps} from '../LocationFilter';
import type {onCloseType} from '@onsmart/ui-kit';
import type {LocationFilter} from 'models/Location';
import type {Styles} from './styles';
interface LocationFilterPopperProps extends Pick<LocationFilterProps, 'onChange'>, Styles {
  value: LocationFilter;
  onPopperClose: (temp?: LocationFilter) => void;
  open: boolean;
  anchorEl: HTMLElement;
  hasChanges: boolean;
  resetTempValue: () => void;
}

const LOCATION_TYPE_OPTIONS = Object.values(LocationType).map((value) => ({
  label: formatLocationTypeLabel(value),
  value,
}));

const useSearchInput = () => {
  const [searchInputText, setSearchInputText] = useState('');

  const handleSearchInputChange = useMemo(() => {
    const debouncedSearch = debounce((inputValue: string) => {
      setSearchInputText(inputValue);
    }, 500);

    return debouncedSearch;
  }, []);

  return {
    searchInputText,
    handleSearchInputChange,
  };
};

const LocationFilterPopperComponent = ({
  anchorEl,
  open,
  value,
  onPopperClose,
  onChange,
  hasChanges,
  resetTempValue,
}: LocationFilterPopperProps) => {
  const [isMenuOpen, onMenuOpen, onMenuClose] = useOpen();
  const {searchInputText, handleSearchInputChange} = useSearchInput();
  const {
    hasLocationValuesSelected,
    filterValue,
    locationTypeOptionSelected,
    locationValuesOptionsSelected,
    setLocationTypeOption,
    setLocationValuesOptions,
  } = useFilterOptions(value);

  const filterPopperRef = useScrollListToBottom(locationValuesOptionsSelected);

  const {isLoading, options: locationValuesOptions} = useLocationSuggestions({
    locationType: filterValue.type,
    searchTerm: searchInputText,
  });

  const handleClose = useCallback(
    (event?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>, eventType?: onCloseType) => {
      if (eventType === 'away' && isMenuOpen) return;

      if (!equal(filterValue, value)) onPopperClose(filterValue);
      else onPopperClose();
    },
    [isMenuOpen, value, filterValue, onPopperClose],
  );

  const handleSubmit = React.useCallback(
    (ev: React.SyntheticEvent<Element, Event>) => {
      onChange(filterValue, ev);
    },
    [filterValue, onChange],
  );

  return (
    <FilterActionPopper
      innerRef={filterPopperRef}
      hasChanges={hasChanges}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      headerTitle="Location"
      disabled={!hasLocationValuesSelected}
      onSubmit={handleSubmit}
      onCancelLabel="Reset"
      onCancel={hasChanges && resetTempValue}
    >
      <PopperContentSection>
        <Autocomplete
          label="Type"
          size={AutocompleteSize.small}
          options={LOCATION_TYPE_OPTIONS}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onChange={setLocationTypeOption}
          value={locationTypeOptionSelected}
          placeholder=""
          isClearable
          autoFocus
        />
      </PopperContentSection>
      <MultiSelectList
        placeholder=""
        label="Values"
        isDisabled={!filterValue.type}
        isLoading={isLoading}
        options={locationValuesOptions}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onChange={setLocationValuesOptions}
        onInputChange={handleSearchInputChange}
        values={locationValuesOptionsSelected}
      />
    </FilterActionPopper>
  );
};

export const Popper = withStyles(styles)(LocationFilterPopperComponent);
