import {feature, featureCollection, point, polygon} from '@turf/helpers';

import {createRoad, getRoad, updateRoad} from './graphql/RoadPanelService';

import type {RoadPanel} from 'models';
interface IPanelProps {
  id: string;
  location: {
    bearing: number;
    center: {
      type: string;
      coordinates: GeoJSON.Point['coordinates'];
    };
    viewshed: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  };
}

export class RoadPanelService {
  static getInstance() {
    return new RoadPanelService();
  };

  getData = async (panel: IPanelProps) => {
    const {raw, curated} = await getRoad(panel.id);

    return {
      raw: raw ? this.parsePanel(raw, panel) : null,
      curated: curated ? this.parsePanel(curated, panel) : null,
    };
  };

  updateCurated = async (road: RoadPanel) => {
    return updateRoad(road);
  };

  createCurated = async (road: RoadPanel) => {
    return createRoad(road);
  };

  private emptyRoad = ({id, location}: IPanelProps): RoadPanel => {
    const [lon, lat] = location.center.coordinates;
    const viewshed = this.getPolygonViewshed(location.viewshed);

    return {
      id,
      data: featureCollection([
        point(location.center.coordinates, {type: 'panel', id: id, bearing: location.bearing}),
        polygon(viewshed.coordinates, {type: 'viewshed'}),
      ]),
      center: {lat, lon},
    };
  };

  private parsePanel = (road: Partial<RoadPanel>, panel: IPanelProps): RoadPanel => {
    const [lon, lat] = panel.location.center.coordinates;
    road.center = {lat, lon};

    const features = road.data.features.map((feat) => {
      const type = feat.properties!.type;
      switch (type) {
        case 'panel':
          return feature(feat.geometry, {...feat.properties, bearing: panel.location.bearing});
        case 'viewshed':
          const viewshed = this.getPolygonViewshed(panel.location.viewshed);
          const geometry = polygon(viewshed.coordinates).geometry;

          return feature(geometry, {...feat.properties, id: panel.id});
        default:
          return feat;
      }
    });

    road.data = featureCollection(features);

    return road as RoadPanel;
  };

  getPolygonViewshed = (viewshed: GeoJSON.Polygon | GeoJSON.MultiPolygon): GeoJSON.Polygon => ({
    type: 'Polygon',
    coordinates: viewshed.type === 'MultiPolygon' ? viewshed.coordinates[0] : viewshed.coordinates,
  });
}
