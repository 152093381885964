import {isEmpty, isNil} from 'lodash';
import {eventChannel} from 'redux-saga';
import {call, fork, put, select, take, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global';
import {getImageDimensions} from 'utils/image';

import {CurationStatus} from '../../../../__generated__/globalTypes';
import {
  getDefaultMarketingImage,
  processMarketingImageUrl,
} from '../../../../components/pages/PanelForm/utils/panelFormat';
import {
  createPanel,
  deletePanel,
  getPanel,
  searchPanelsIds,
  updatePanel,
} from '../../../../services/graphql/PanelService';
import {isInProduction} from '../../../../utils/app';
import {convertObjToElasticQueryString} from '../../../../utils/elasticSearch';
import {createImageMapUrl} from '../../../../utils/models/panel';
import {debounce, takeLeading} from '../../../../utils/sagas';
import {createActivityRecordSaga} from '../activityQueue';
import {actions} from './actions';
import {roadsFlow} from './roads';

import type {Action} from 'redux-act';
import type {ActivityData, TopicType} from '../../../../utils/models/activityQueue';
import type {SagaReturnType} from '../../../../utils/sagas';
import type {IPanelInput} from './reducers';
import type {RootState} from 'config/redux/rootReducer';
import type {GraphqlCoreError} from '../../../../services/graphql/CoreClient';
import type {
  ICreatePanelInput,
  IGetPanelResponse,
  IUpdatePanelInput,
} from '../../../../services/graphql/PanelService';

class BadPanelLocationError extends Error {}
class BadPanelCurationStatusError extends Error {}

function searchPanelListIds(
  filters: RootState['panels']['filters'],
  pagination: RootState['panels']['pagination'],
) {
  const {
    searchTerm,
    curationStatus,
    market: marketCode,
    dataOrigin,
    panelStatus,
    media,
    location,
    inventory: ids,
    acceptableImage: marketingImageAcceptable,
    prime,
    format,
  } = filters;

  const queryFilters = {
    ...(!isEmpty(location) && {[location.typeKey]: location.values}),
    curationStatus,
    marketCode,
    dataOrigin,
    panelStatus,
    _id: ids,
    marketingImageAcceptable,
    media,
    prime,
    format,
  };

  const FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY = {
    'media.category': 'mediaCategory',
    'media.subCategory': 'mediaSubCategory',
    'media.names': 'mediaNameSynonyms',
  };

  const query = convertObjToElasticQueryString({
    elasticKeyDictionary: FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY,
    obj: queryFilters,
    rawQueryString: searchTerm,
  });

  return searchPanelsIds({
    skip: 0,
    limit: 10000,
    sort: pagination.sort,
    query,
  });
}

function* buildPanelNavigationIndex(action: Action<string>) {
  const panelId = action.payload;
  const defaultNavigationIndex = {
    [panelId]: {
      currentId: panelId,
      prevId: undefined,
      nextId: undefined,
    },
  };

  try {
    const {filters, pagination}: RootState['panels'] = yield select<RootState>(
      (state) => state.panels,
    );
    const {data}: SagaReturnType<typeof searchPanelListIds> = yield call(
      searchPanelListIds,
      filters,
      pagination,
    );

    const navigationIndex = data.reduce(
      (navIndex, panel, index, array) => {
        navIndex[panel.id] = {
          currentId: panel.id,
          prevId: array[index - 1]?.id,
          nextId: array[index + 1]?.id,
        };

        return navIndex;
      },
      {...defaultNavigationIndex},
    );

    yield put(actions.setPanelNavigationIndex(navigationIndex));

    return navigationIndex;
  } catch (err) {
    console.error('[buildPanelNavigationIndex]', err);

    yield put(actions.setPanelNavigationIndex(defaultNavigationIndex));
  }
}

function selectPanelNavigationItem(state: RootState, panelId: string) {
  return state.panelForm.panelNavigationIndex[panelId];
}

function* loadPanel(action: Action<string>) {
  const panelId = action.payload;

  try {
    yield put(globalActions.showLoading());
    yield put(actions.showPanelFormLoading());

    const panel: SagaReturnType<typeof getPanel> = yield call(getPanel, panelId);

    let marketingImageUrl = processMarketingImageUrl(panel.marketingImageUrl);

    if (!marketingImageUrl) {
      marketingImageUrl = yield call(tryToSetMarketingImageUrl, panel);
    }

    const mapImageUrl = getMapImageUrlOrDefault(panel);
    const formattedPanel = {
      ...panel, 
      marketingImageUrl, 
      mapImage: { ...panel.mapImage, url: mapImageUrl }
    };

    yield put(actions.setPanel(formattedPanel));
  } catch (err) {
    console.error('[loadPanel]', err);
    yield put(globalActions.showMessage(`[Error]: Could not fetch surface ${panelId}`));
  } finally {
    yield put(actions.hidePanelFormLoading());
    yield put(globalActions.hideLoading());
  }
}

function getMapImageUrlOrDefault({ mapImage, location }: Pick<IGetPanelResponse, 'mapImage' | 'location'>) {
  if(mapImage?.url) return mapImage.url;

  const {bearing, center, viewshed} = location ?? {};
  return createImageMapUrl({bearing, center, viewshed});
};

function* tryToSetMarketingImageUrl({
  id,
  assets,
}: Pick<IGetPanelResponse, 'id' | 'marketCode' | 'assets'>) {
  const marketingImageUrl = getDefaultMarketingImage({panelId: id, assets});

  if (!marketingImageUrl) return '';

  try {
    const img = yield call(getImageDimensions, `${marketingImageUrl}&w=10`);
    const isValid = img.width > 1;

    if (isValid) {
      const panel = yield call(updatePanel, {id, marketingImageUrl});
      yield put(globalActions.showMessage('Marketing image updated'));

      return panel.marketingImageUrl;
    }

    return '';
  } catch (error) {
    return '';
  }
}

function getPanelMutationErrorMessages(err: GraphqlCoreError, panelId?: string): string[] {
  if (Array.isArray(err.response?.errors)) {
    let errorMessages = [];

    errorMessages = err.response.errors
      .filter(({extensions}) => extensions.code === 'INTERNAL_SERVER_ERROR')
      .map(({message}) => `[Error]: ${message}`);

    const hasBadUserInputErrors = err.response.errors.some(
      ({extensions}) => extensions.code === 'BAD_USER_INPUT',
    );

    if (hasBadUserInputErrors) {
      errorMessages.push('[Error]: You must fill required form fields');
    }

    return errorMessages.length > 0 ? errorMessages : [`Unable to save ${panelId}`];
  }

  return ['[Error]: sending error info…'];
}

function* showPanelMutationErrorMessages(err: GraphqlCoreError, panelId?: string) {
  const messages = getPanelMutationErrorMessages(err, panelId);

  for (let index in messages) {
    yield put(globalActions.showMessage(messages[index]));
  }
}

function mapCreatePanelInput(data: IPanelInput) {
  const panelInput: ICreatePanelInput = {
    inventoryNumber: data.inventoryNumber,
    curationStatus: data.curationStatus,
    format: data.format,
    marketCode: data.marketCode,
    mediaName: data.mediaName,
    panelStatus: data.panelStatus,
    curationNotes: data.curationNotes,
    gtReferenceNumber: data.gtReferenceNumber,
    prime: data.prime,
    locationDescription: data.locationDescription,
    location: data.location,
    marketingImageUrl: data.marketingImageUrl,
  };

  return panelInput;
}

function validateLocationIfExists(location: IPanelInput['location']) {
  if (!location) {
    throw new BadPanelLocationError('Error: Invalid location. Please open map dialog and set a location center.');
  }
}

function validateCurationStatus({ location, curationStatus }: IPanelInput) {
  if (curationStatus) {
    const restrictedValues = [CurationStatus.COMPLETED, CurationStatus.PUBLISHED]

    if (restrictedValues.includes(curationStatus) && !location?.viewshed?.coordinates?.length)
      throw new BadPanelCurationStatusError("Can't set panel curation status to Completed or Published w/o a viewshed")
  }
}

function* createPanelSaga(action: ReturnType<typeof actions.createPanel>) {
  try {
    yield put(globalActions.showLoading());

    const panel = mapCreatePanelInput(action.payload);

    validateLocationIfExists(panel.location);

    const result: SagaReturnType<typeof createPanel> = yield call(createPanel, panel);

    yield put(
      actions.setPanelNavigationIndex({
        [result.id]: {currentId: result.id},
      }),
    );

    yield put(globalActions.showMessage('Panel saved'));
    yield put(globalActions.navigateToRoute(`/panels/${result.id}`));
  } catch (err) {
    if (err instanceof BadPanelLocationError) {
      yield put(globalActions.showMessage(err.message));
    } else {
      yield fork(showPanelMutationErrorMessages, err);
    }
  } finally {
    yield put(globalActions.hideLoading());
  }
}

function selectCurrentPanel(state: RootState) {
  return state.panelForm.panel;
}

function mapUpdatePanelInput(data: IPanelInput & {id: string}) {
  let location;

  if (data.location) {
    let {bearing, center, viewshed} = data.location;

    if (center) {
      center = {
        type: center.type,
        coordinates: center.coordinates,
      };
    }

    location = {bearing, center, viewshed};
  }

  const panelInput: IUpdatePanelInput = {
    id: data.id,
    mediaCategory: data.mediaCategory,
    mediaSubCategory: data.mediaSubCategory,
    mediaNameSynonyms: data.mediaNameSynonyms,
    curationStatus: data.curationStatus,
    curationNotes: data.curationNotes,
    marketingImageAcceptable: data.marketingImageAcceptable,
    marketingImageUrl: data.marketingImageUrl,
    curatedRoadSegments: data.curatedRoadSegments,
    prime: data.prime,
    gtReferenceNumber: data.gtReferenceNumber,
    facing: data.facing,
    location,
  };

  return Object.keys(panelInput).reduce((panelData, key) => {
    if (!isNil(panelInput[key])) {
      panelData[key] = panelInput[key];
    }

    return panelData;
  }, {} as IUpdatePanelInput);
}

function* updatePanelSaga(action: ReturnType<typeof actions.updatePanel>) {
  const {id, ...data} = action.payload;

  try {
    yield put(globalActions.showLoading());

    const dataKeys = Object.keys(data);

    if (dataKeys.length > 0) {
      const panel: SagaReturnType<typeof getPanel> = yield call(getPanel, id);
      const panelInput = mapUpdatePanelInput(action.payload);
      const activityData = getPanelActivityData(panel, data);

      validateCurationStatus({
        ...panelInput,
        location: { 
          ...panelInput.location,
          viewshed: panelInput.location?.viewshed ?? panel.location?.viewshed
        },
      });

      const result: SagaReturnType<typeof updatePanel> = yield call(updatePanel, panelInput);

      if (!isNil(activityData)) {
        yield put(actions.createPanelActivity({...activityData, type: 'Update'}));
      }

      const {id: currentPanelId}: RootState['panelForm']['panel'] = yield select(
        selectCurrentPanel,
      );

      if (id === currentPanelId) {
        yield put(actions.setPanel(result));
      }
    }

    yield put(globalActions.showMessage(`Panel ${id} saved`));
  } catch (err) {
    if (err instanceof BadPanelCurationStatusError) {
      yield put(globalActions.showMessage(err.message));
      yield fork(rollbackPanelCurationStatusState, id);
    } else {
      yield fork(showPanelMutationErrorMessages, err, id);
    }
  } finally {
    yield put(globalActions.hideLoading());
  }
}

function* rollbackPanelCurationStatusState(panelId: string) {
  const panel: SagaReturnType<typeof getPanel> = yield call(getPanel, panelId);
  const {id: currentPanelId}: RootState['panelForm']['panel'] = yield select(
    selectCurrentPanel,
  );

  if (panelId === currentPanelId) {
    yield put(actions.setPanelInput({ curationStatus: panel.curationStatus }));
  }
}

function getPanelActivityData(panel: IGetPanelResponse, panelInput: IPanelInput) {
  const locationUrl = isInProduction
    ? window.location.href
    : 'https://stg.mad.beakyn.com/' + window.location.hash;

  const model = {
    id: panel.id,
    name: panel.name,
    type: 'Panel',
    locationUrl,
  };

  let topic: TopicType;
  let data: ActivityData;

  if (panelInput.curationStatus) {
    topic = 'curation';
    data = {
      n: panelInput.curationStatus,
      o: panel.curationStatus,
    };

    return {topic, data, model};
  }

  if (!isNil(panelInput.location)) {
    topic = 'location';
    data = isEmpty(panelInput.mapImage) ? [] : [panelInput.mapImage?.url];

    return {topic, data, model};
  }

  return undefined;
}

function* removePanelSaga(action: Action<string>) {
  const panelId = action.payload;

  try {
    yield call(deletePanel, panelId);

    yield put(globalActions.navigateToRoute('/panels'));
    yield put(globalActions.showMessage('Panel removed'));
  } catch (err) {
    console.error(err);
    yield put(globalActions.showMessage('Error'));
  }
}

const LEFT = 37;
const RIGHT = 39;
function getKeyPressChannel(filter: (e: KeyboardEvent) => boolean) {
  return eventChannel((emitter) => {
    const tagNameIs = (e: any, name: string) => e.tagName.toLowerCase() === name;
    const isEditInputType = (e: any) =>
      ['text', 'number', 'date', 'email'].includes(e.getAttribute('type'));

    const textComponent = (t: any) =>
      isEditInputType(t) || tagNameIs(t, 'textarea') || tagNameIs(t, 'select');

    const listener = (e: KeyboardEvent) => {
      if (filter(e) && !textComponent(e.target)) {
        const formFomartUrl = /\/panels\/[^new]+./;
        if (formFomartUrl.test(window.location.href)) {
          e.preventDefault();
        }
        emitter(e.keyCode === LEFT ? 'prevId' : 'nextId');
      }
    };

    window.addEventListener('keydown', listener, false);

    return () => {
      window.removeEventListener('keydown', listener, false);
    };
  });
}

type NavigationDirection = 'prevId' | 'nextId';

function* handleNavigateKeyPress() {
  const channel = yield call(
    getKeyPressChannel,
    (e: KeyboardEvent) =>
      // just left or right key pressed
      !e.ctrlKey &&
      !e.altKey &&
      !e.shiftKey &&
      !e.metaKey &&
      (e.keyCode === LEFT || e.keyCode === RIGHT),
  );

  while (true) {
    const key: NavigationDirection = yield take(channel);

    try {
      const navItem: SagaReturnType<typeof selectPanelNavigationItem> = yield select<RootState>(
        (state) => selectPanelNavigationItem(state, state.panelForm.panel.id),
      );

      const {pathname}: RootState['router']['location'] = yield select<RootState>(
        (state) => state.router.location,
      );

      const formUrlPattern = /\/panels\/[^new]+./;
      const url = `${pathname}`;

      if (
        formUrlPattern.test(url) && // if url is other then "/panels/new"
        navItem[key] &&
        !url.includes(navItem[key])
      ) {
        yield put(globalActions.navigateToRoute(`/panels/${navItem[key]}`));
      }
    } catch (error) {
      console.error(error);
      channel.close();
      break;
    }
  }
}

export function* panelFormSaga() {
  yield takeLeading(actions.loadPanel, loadPanel);
  yield takeLeading(actions.buildPanelNavigationIndex, buildPanelNavigationIndex);
  yield takeLeading(actions.createPanel, createPanelSaga);
  yield takeLatest(actions.updatePanel, updatePanelSaga);
  yield takeLeading(actions.removePanel, removePanelSaga);
  yield debounce(1000, actions.createPanelActivity, createActivityRecordSaga);

  yield fork(handleNavigateKeyPress);
  yield fork(roadsFlow);
}
