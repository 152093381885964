import {useEffect, useMemo} from 'react';
import {useQuery} from 'react-query';
import {gql, gqlRequest, queryClient} from '../services/graphql/CoreClient';

import type {SearchMediaCategories} from './__generated__/SearchMediaCategories';

interface MediaCategory {
  name: string;
  subcategories?: {name: string}[];
}

interface Option {
  text: string;
  value: string;
}

interface MediaCategoriesOptions {
  categories: string[];
  categoriesOptions: Option[];
}

interface MediaSubcategoriesOptions {
  subcategories: string[];
  subcategoriesOptions: Option[];
}

const MEDIA_CATEGORIES_KEYS = {
  all: ['mediaCategories'],
};

const MEDIA_CATEGORIES_QUERY = gql`
  query SearchMediaCategories {
    searchMediaCategories {
      categories {
        name
        subcategories {
          name
        }
      }
    }
  }
`;

const fetchMediaCategories = async (signal: AbortSignal): Promise<MediaCategory[]> => {
  const {searchMediaCategories} = await gqlRequest<SearchMediaCategories>(MEDIA_CATEGORIES_QUERY);

  return searchMediaCategories.categories;
};

const reduceMediaCategories = (mediaOptions: MediaCategoriesOptions, category: MediaCategory) => {
  const categoryName = category.name;
  const categoryOption = {
    text: categoryName,
    value: categoryName,
  };

  mediaOptions.categories.push(categoryName);
  mediaOptions.categoriesOptions.push(categoryOption);

  return mediaOptions;
};

const reduceMediaSubcategories =
  (mediaCategory: string) => (mediaOptions: MediaSubcategoriesOptions, category: MediaCategory) => {
    if (category.name !== mediaCategory) return mediaOptions;

    const {subcategories = []} = category;
    const subcategoriesNames = subcategories
      .map(({name}) => name)
      .sort((name1, name2) => name1.localeCompare(name2));

    const subcategoriesOptions = subcategoriesNames.map((name) => ({
      text: name,
      value: name,
    }));

    mediaOptions.subcategories = subcategoriesNames;
    mediaOptions.subcategoriesOptions = subcategoriesOptions;

    return mediaOptions;
  };

export function useMediaCategories(mediaCategory?: string) {
  const {
    data = [],
    isLoading,
    error,
  } = useQuery(MEDIA_CATEGORIES_KEYS.all, ({signal}) => fetchMediaCategories(signal), {
    staleTime: 1000 * 60 * 30,
  });

  const {categories, categoriesOptions} = useMemo(
    () =>
      data
        .sort((category1, category2) => category1.name.localeCompare(category2.name))
        .reduce(reduceMediaCategories, {categories: [], categoriesOptions: []}),
    [data],
  );

  const {subcategories, subcategoriesOptions} = useMemo(
    () =>
      data.reduce(reduceMediaSubcategories(mediaCategory), {
        subcategories: [],
        subcategoriesOptions: [],
      }),
    [data, mediaCategory],
  );

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(MEDIA_CATEGORIES_KEYS.all);
    };
  }, []);

  return {
    categories,
    categoriesOptions,
    subcategories,
    subcategoriesOptions,
    isLoading,
    error,
  };
}
