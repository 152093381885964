import type {FilterColorChipProps} from '@onsmart/ui-kit';
import {FilterColorChip, filterColorChipStylesDecorator} from '@onsmart/ui-kit';
import * as _isEmpty from 'lodash.isempty';

import {truncateFilterLabel} from 'utils/filters';

import type {MediaFilter} from 'models/MediaType';

type Props = FilterColorChipProps<MediaFilter>;

const getLabels = (value: MediaFilter) => {
  const {names, category, subCategory} = value || ({} as MediaFilter);

  const mediaTypeLabel = names && names.length ? truncateFilterLabel('Media Type', names) : '';
  const categoryLabel = category ? `Category: ${category}` : '';
  const subCategoryLabel = subCategory ? `Subcategory: ${subCategory}` : '';

  return {mediaTypeLabel, categoryLabel, subCategoryLabel};
};
class Chip extends FilterColorChip<Props, MediaFilter> {
  getLabel(value: MediaFilter) {
    const {mediaTypeLabel, categoryLabel, subCategoryLabel} = getLabels(value);

    if (_isEmpty(mediaTypeLabel)) {
      return [categoryLabel, subCategoryLabel].filter((s) => s).join('; ');
    } else {
      return mediaTypeLabel;
    }
  }
}

export default filterColorChipStylesDecorator(Chip);
