import createStyles from '@material-ui/core/styles/createStyles';

import type {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    betaText: {
      color: '#888888',
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'uppercase',
      display: 'inline-block',
    },
  });

export type Styles = WithStyles<typeof styles>;
