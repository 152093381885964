import type {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import type {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    listRoot: {
      marginTop: 0,
    },
    emptyListRoot: {
      marginBottom: 0,
    },
  });

export type Styles = WithStyles<typeof styles>;
