import {truncateArray} from './string';

export const truncateFilterLabel = (modelName: string, value: any[]) => {
  const truncateSize = 2;
  const leftFilters = value.length - truncateSize;
  const truncate = (leftFilters > 1 ? truncateArray(value, truncateSize) : value).join(', ');

  if (leftFilters > 1) {
    return `${modelName}: ${truncate} and ${leftFilters} more`;
  } else {
    return `${modelName}: ${truncate}`;
  }
};
