import {useEffect, useMemo} from 'react';
import {debounce} from 'utils/promise';

export const useDebouncedCallback = <Fn extends (...args: any[]) => void>(callback: Fn, delay = 500) => {
  const handler = useMemo(() => debounce(callback, delay), [callback, delay]);

  useEffect(() => {
    return handler.cancel;
  }, [handler]);

  return handler;
};

export default useDebouncedCallback;
