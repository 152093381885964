import {RoadSegmentSource} from '__generated__/globalTypes';

import {fetchQuery, gql, gqlRequest, queryClient} from './CoreClient';

import type {RoadPanel} from 'models';
import type {GetRoad, GetRoadVariables} from './__generated__/GetRoad';
import type {UpdateRoad, UpdateRoadVariables} from './__generated__/UpdateRoad';
import type {CreateRoad, CreateRoadVariables} from './__generated__/CreateRoad';
import type {DeleteRoad, DeleteRoadVariables} from './__generated__/DeleteRoad';

const GET_ROAD = gql`
  query GetRoad($id: ID!) {
    clippedRoadSegments: getRoadSegments(source: CLIPPED, id: $id) {
      data
      type
      updatedAt
    }

    curatedRoadSegments: getRoadSegments(source: MAD_CURATED, id: $id) {
      data
      type
      updatedAt
    }
  }
`;

const UPDATE_ROAD_MUTATION = gql`
  mutation UpdateRoad($payload: RoadSegmentsInput!, $id: ID!) {
    updateRoadSegments(data: $payload, id: $id) {
      data
      type
      updatedAt
    }
  }
`;

const DELETE_ROAD_MUTATION = gql`
  mutation DeleteRoad($id: ID!, $source: RoadSegmentSource!) {
    deleteRoadSegments(id: $id, source: $source) {
      type
    }
  }
`;

const CREATE_ROAD_MUTATION = gql`
  mutation CreateRoad($payload: RoadSegmentsInput!, $id: ID!) {
    createRoadSegments(data: $payload, id: $id) {
      data
      type
      updatedAt
    }
  }
`;

type SourceKey = keyof typeof RoadSegmentSource;

export const getRoad = async (id: string) => {
  const resp = await fetchQuery<GetRoad, GetRoadVariables>({
    queryKey: ['road', id],
    queryDocument: GET_ROAD,
    queryVariables: {id},
    staleTime: 1000 * 60 * 10, // 10min
  });

  const {clippedRoadSegments, curatedRoadSegments} = resp;
  return {
    raw: clippedRoadSegments as Partial<RoadPanel>,
    curated: curatedRoadSegments as Partial<RoadPanel>,
  };
};

export const updateRoad = async ({id, data}: RoadPanel) => {
  const source = RoadSegmentSource.MAD_CURATED;
  const payload = {data, source};

  const {updateRoadSegments: response} = await gqlRequest<UpdateRoad, UpdateRoadVariables>(
    UPDATE_ROAD_MUTATION,
    {id, payload},
  );

  queryClient.setQueryData<GetRoad>(['road', id], (old) => ({
    ...old,
    curatedRoadSegments: response,
  }));

  return response as unknown as RoadPanel;
};

export const createRoad = async ({id, data}: RoadPanel) => {
  const source = RoadSegmentSource.MAD_CURATED;
  const payload = {source, data};

  const {createRoadSegments: response} = await gqlRequest<CreateRoad, CreateRoadVariables>(
    CREATE_ROAD_MUTATION,
    {id, payload},
  );

  queryClient.setQueryData<GetRoad>(['road', id], (old) => ({
    ...old,
    curatedRoadSegments: response,
  }));

  return response as unknown as RoadPanel;
};

export const deleteRoad = async ({id}: RoadPanel, sourceKey: SourceKey) => {
  const source = RoadSegmentSource[sourceKey];

  await gqlRequest<DeleteRoad, DeleteRoadVariables>(DELETE_ROAD_MUTATION, {id, source});

  queryClient.removeQueries(['road', source, id]);
};
