import type {
  FilterColorChipProps} from '@onsmart/ui-kit';
import {
  FilterColorChip,
  filterColorChipStylesDecorator,
} from '@onsmart/ui-kit';
import { isEmpty } from 'lodash';

import type {LocationFilter} from 'models/Location';
import {truncateFilterLabel} from 'utils/filters';
import { formatLocationTypeLabel } from './utils/locationTypeFormatter';

interface Props extends FilterColorChipProps<LocationFilter> {}

const getValuesLabel = (value: LocationFilter) => {
  const {type, values} = value || ({} as LocationFilter);

  const typeLabel = formatLocationTypeLabel(type);
  return !!values && !!values.length ? truncateFilterLabel(typeLabel, values) : '';
};

class Chip extends FilterColorChip<Props, LocationFilter> {
  getLabel(value: LocationFilter) {
    if (isEmpty(value?.values)) {
      return 'Location';
    } else {
      return getValuesLabel(value);
    }
  }
}

export default filterColorChipStylesDecorator(Chip);
