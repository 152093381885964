import {ScoutError} from '@onsmart/ui-kit';
import {delay, eventChannel} from 'redux-saga';
import {all, call, cancelled, fork, put, select, take, takeLatest} from 'redux-saga/effects';

import {URLParams} from './UrlParams';

import type {History, Location} from 'history';
import type {Action, SimpleActionCreator, EmptyActionCreator} from 'redux-act';
import type {RootState} from 'config/redux/rootReducer';

interface ConfigPrismItem<R> {
  defaultValue?: R;
  action: SimpleActionCreator<R>;
  forceFetch?: (value: R) => Action<any>;
  selector: (state: RootState) => R;
  listeners?: (EmptyActionCreator | SimpleActionCreator<any>)[];
  stringToValue?: (param: string) => IterableIterator<any>;
  valueToString?: (param: R) => IterableIterator<any>;
  history: History<any>;
}

function getQueryValue<T>(location: Location, paramName: string, config: ConfigPrismItem<T>) {
  const locationParams = new URLParams(location.search);

  const {defaultValue, stringToValue} = config;
  const valueString = locationParams.get(paramName);
  const value = valueString === null ? Promise.resolve(defaultValue) : stringToValue(valueString);
  return value;
}

export function locationChange(history: History<any>) {
  return eventChannel<Location>((emmitter) => {
    const destroy = history.listen((loc, action) => {
      if (action !== 'REPLACE') {
        emmitter(loc);
      }
    });

    return () => destroy();
  });
}

function* handleLocationChange<T>(
  processUpdate: (loc: Location) => void,
  config: ConfigPrismItem<T>,
) {
  const event = yield call(locationChange, config.history);
  try {
    while (true) {
      const loc: Location = yield take(event);

      yield call(processUpdate, loc);
    }
  } finally {
    if (cancelled()) {
      event.close();
    }
  }
}

const getLocationUpdateHandle = <T>(paramName: string, config: ConfigPrismItem<T>) => {
  return function* locationUpdateHandle(location: Location) {
    // Ignore the event if the location update was induced by ourselves.
    if ((location as any).ignoreLocationUpdate) return;

    const {selector} = config;
    // Read the values of the watched parameters
    const queryValue = yield call(getQueryValue, location, paramName, config);
    const actualValue = yield select(selector);

    // Dispatch the action to update the state if needed.
    if (queryValue !== actualValue) {
      yield call(putAction, paramName, queryValue, config, location);
    }
  };
};

function* putAction<T>(
  paramName: string,
  value: T,
  config: ConfigPrismItem<T>,
  location: Location<any>,
) {
  const {action, forceFetch} = config;

  const item = action(value);
  const payload = {meta: {ignoreStateUpdate: true}, ...item};
  const isForced = forceFetch && location.search.includes('forceFetch');
  if (isForced && location.search.includes(paramName)) {
    const locParams = new URLParams(location.search);

    locParams.delete('forceFetch');

    yield put(forceFetch(payload.payload));
    yield call(setNewLocation, location, `${locParams}`, config.history);
  } else if (!isForced) {
    yield put(payload);
  }
}

const getStateUpdateHandle = <T>(paramName: string, config: ConfigPrismItem<T>) => {
  return function* stateUpdateHandle({
    meta,
  }: Action<T, {ignoreStateUpdate: boolean; replaceState: boolean}>) {
    if (meta && meta.ignoreStateUpdate) return;

    yield call(delay, 300);

    yield call(processStateUpdate, paramName, config);
  };
};

function* processStateUpdate<T>(paramName: string, config: ConfigPrismItem<T>) {
  const {location} = config.history;
  const {selector, valueToString} = config;

  // Replace configured parameter with its value in the state.
  const value = yield select(selector);
  const urlValue = yield call(valueToString, value);

  // Parse the current location's query string.
  const newLocationSearchString = getNewLocationString(location, paramName, urlValue);
  const oldLocationSearchString = location.search || '?';

  // Only update location if something changed.
  if (newLocationSearchString !== oldLocationSearchString) {
    // Update location (but prevent triggering a state update).
    yield call(setNewLocation, location, newLocationSearchString, config.history);
  }
}

const getNewLocationString = (location: Location, paramName: string, urlValue: any) => {
  const locationParams = new URLParams(location.search);

  if (!urlValue) {
    locationParams.delete(paramName);
  } else {
    locationParams.set(paramName, urlValue);
  }

  return `?${locationParams}`;
};

interface ConfigPayload<R> extends Omit<ConfigPrismItem<R>, 'stringToValue' | 'valueToString'> {
  stringToValue?: (param: string) => IterableIterator<any> | R;
  valueToString?: (param: R) => IterableIterator<any> | string;
}

function* setNewLocation(
  location: Location<any>,
  newLocationSearchString: string,
  history: History<any>,
) {
  const newLocation = {
    ignoreLocationUpdate: true,
    pathname: location.pathname,
    search: newLocationSearchString,
    hash: location.hash,
  };
  yield call(history.replace, newLocation);
}

function* fillConfig<T extends any = any>(config: ConfigPayload<T>) {
  const {
    stringToValue = (s: string) => s,
    valueToString = (v: T) => (!!v ? `${v}` : ''),
    listeners = [],
  } = config;

  if (!config.defaultValue) {
    config.defaultValue = yield select(config.selector);
  }

  return {
    ...config,
    listeners,

    stringToValue: function* (p: string) {
      return yield call(stringToValue, p);
    },
    valueToString: function* (p: T) {
      return yield call(valueToString, p);
    },
  } as ConfigPrismItem<T>;
}

const INITIAL_QUERY_SYNC_STATE = {} as {[page: string]: {[param: string]: ConfigPrismItem<any>}};

export function* querySync<T extends any = any>(
  paramName: string,
  partialConfig: ConfigPayload<T>,
) {
  const parsedConfig: ConfigPrismItem<T> = yield call(fillConfig, partialConfig);

  yield setStoreConfig(paramName, parsedConfig);

  // Sync location to store on every location change, and vice versa.
  const stateToUrlHandle = getStateUpdateHandle(paramName, parsedConfig);
  const locationToStateHandle = getLocationUpdateHandle(paramName, parsedConfig);
  const actions = [parsedConfig.action, ...parsedConfig.listeners];

  yield takeLatest(actions, stateToUrlHandle);
  yield fork(handleLocationChange, locationToStateHandle, parsedConfig);

  // init from location
  const currentLoc = partialConfig.history.location;
  if (currentLoc.search) {
    yield fork(locationToStateHandle, currentLoc);
  }
}

export function* startQuerySyncFromState(history: History<any>, params: string[]) {
  const currentPage = history.location.pathname;

  yield validateParams(params, currentPage);

  yield all(
    params.map(function* (key: string) {
      const config = INITIAL_QUERY_SYNC_STATE[currentPage][key];

      yield call(processStateUpdate, key, config);
    }),
  );
}

function setStoreConfig(paramName: string, config: ConfigPrismItem<any>) {
  const page = config.history.location.pathname;
  const pageConfig = INITIAL_QUERY_SYNC_STATE[page] || {};

  INITIAL_QUERY_SYNC_STATE[page] = {
    ...pageConfig,
    [paramName]: config,
  };
}

function validateParams(params: string[], page: string) {
  if (!INITIAL_QUERY_SYNC_STATE[page]) {
    throw new ScoutError(`[prism] Url page (${page}) is not configured`, {
      storeConfig: INITIAL_QUERY_SYNC_STATE,
    });
  }

  const paramNotConfigured = params.find(
    (key) => !Object.keys(INITIAL_QUERY_SYNC_STATE[page]).includes(key),
  );

  if (paramNotConfigured) {
    throw new ScoutError(`[prism] Url param (${paramNotConfigured}) is not configured`, {
      storeConfig: INITIAL_QUERY_SYNC_STATE,
    });
  }
}

export const stringTransforms = {
  valueToString: (v: string) => (!!v ? encodeURIComponent(v) : ''),
  stringToValue: (s: string) => (!!s ? decodeURIComponent(s) : s),
};
