import {routerMiddleware as reactRouterReduxMiddleware} from 'react-router-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';

import {history} from 'config';
import {SentryService} from 'services/SentryService';

import {rootReducer} from './rootReducer';
import SagaManager from './rootSaga';

const __DEV__ = !!module.hot;

const routerMiddleware = reactRouterReduxMiddleware(history);
const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    const {user, router} = store.getState() as any;

    SentryService.getInstance().report(err, {
      saga: true,
      state: {user, router},
    });
  },
});

const middlewares = [routerMiddleware, SentryService.getInstance().middleware, sagaMiddleware];
const storeEnhancers: any = [applyMiddleware(...middlewares)];

if (__DEV__) {
  const debugEnhancer =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();

  if (debugEnhancer) {
    storeEnhancers.push(debugEnhancer);
  } else {
    console.log(
      'Download https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd to enable hmr on store',
    );
  }
}

export const store = createStore(rootReducer, compose(...storeEnhancers));
SagaManager.startSaga(sagaMiddleware);

if (__DEV__ && module.hot) {
  module.hot.accept('./rootReducer', () =>
    store.replaceReducer(require('./rootReducer').rootReducer),
  );

  module.hot.accept('./rootSaga', () => {
    SagaManager.cancelSaga(store);
    require('./rootSaga').default.startSaga(sagaMiddleware);
  });
}
