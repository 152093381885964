import * as React from 'react';
import {Component} from 'react';
import {ServiceNavigationMenu} from '@onsmart/ui-kit';

import type {ServiceOption} from '@onsmart/ui-kit';

interface Props {
  serviceOptions: ServiceOption[];
}

export class MainNavLeft extends Component<Props> {
  render() {
    return <ServiceNavigationMenu options={this.props.serviceOptions} title={'Services'} />;
  }
}
