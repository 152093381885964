import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {MediaTypeField} from '../__generated__/globalTypes';
import {gql, gqlRequest} from '../services/graphql/CoreClient';

import type {
  SearchMediaTypeSynonyms,
  SearchMediaTypeSynonymsVariables,
} from './__generated__/SearchMediaTypeSynonyms';

interface MediaSynonm {
  name: string;
}

interface MediaSynonymsData {
  mediaSynonyms: string[];
  mediaSynonymsOptions: {
    label: string;
    value: string;
  }[];
}

interface UseMediaSynonymsProps {
  panelStatus?: string;
  mediaCategory?: string;
  mediaSubCategory?: string;
  field?: MediaTypeField;
}

const MEDIA_SYNONYMS_KEYS = {
  all: ['mediaSynonyms'] as const,
  lists: () => [...MEDIA_SYNONYMS_KEYS.all, 'list'] as const,
  list: (filters) => [...MEDIA_SYNONYMS_KEYS.lists(), {filters}] as const,
};

const MEDIA_SYNONYMS_QUERY = gql`
  query SearchMediaTypeSynonyms(
    $field: MediaTypeField!
    $panelStatus: String
    $mediaCategory: String
    $mediaSubCategory: String
  ) {
    searchMediaTypes(
      field: $field
      panelStatus: $panelStatus
      mediaCategory: $mediaCategory
      mediaSubCategory: $mediaSubCategory
    ) {
      total
      data {
        name
      }
    }
  }
`;

const fetchMediaSynonyms = async (variables: SearchMediaTypeSynonymsVariables) => {
  const {searchMediaTypes} = await gqlRequest<
    SearchMediaTypeSynonyms,
    SearchMediaTypeSynonymsVariables
  >(MEDIA_SYNONYMS_QUERY, variables);

  return searchMediaTypes.data;
};

const reduceMediaSynonyms = (mediaData: MediaSynonymsData, mediaSynonym: MediaSynonm) => {
  const option = {
    label: mediaSynonym.name,
    value: mediaSynonym.name,
  };

  mediaData.mediaSynonyms.push(mediaSynonym.name);
  mediaData.mediaSynonymsOptions.push(option);

  return mediaData;
};

export function useMediaSynonyms({
  panelStatus = 'Active',
  field = MediaTypeField.MEDIA_NAME_SYNONYMS,
  mediaCategory,
  mediaSubCategory,
}: UseMediaSynonymsProps = {}) {
  const variables = useMemo(
    () => ({
      field,
      panelStatus: panelStatus,
      ...(mediaCategory && {mediaCategory}),
      ...(mediaSubCategory && {mediaSubCategory}),
    }),
    [panelStatus, mediaCategory, mediaSubCategory, field],
  );

  const {
    data = [],
    isLoading,
    error,
  } = useQuery(
    MEDIA_SYNONYMS_KEYS.list(variables),
    () => fetchMediaSynonyms(variables),
    {staleTime: 1000 * 60 * 30}, // 30min
  );

  const {mediaSynonyms, mediaSynonymsOptions} = useMemo(
    () =>
      data
        .sort((media1, media2) => media1.name.localeCompare(media2.name))
        .reduce(reduceMediaSynonyms, {mediaSynonyms: [], mediaSynonymsOptions: []}),
    [data],
  );

  return {
    mediaSynonyms,
    mediaSynonymsOptions,
    isLoading,
    error,
  };
}
