import equal from 'fast-deep-equal';
import _get from 'lodash.get';

import type {Indexed, Market} from 'models';

export const map = (f) => (array: any[]) => array.map(f);

export const sortBy = (propName: string) => (array: any[]) => {
  return array.sort((a, b) => {
    let result = 0;
    if (a[propName] > b[propName]) {
      result = 1;
    } else if (a[propName] < b[propName]) {
      result = -1;
    }
    return result;
  });
};

export const indexedToArray = <T>(list: Indexed<T>) => Object.keys(list).map((i) => list[i] as T);
export const arrayToIndexed = <T, K extends keyof T>(list: T[], key: K) =>
  list.reduce((acc, item) => {
    acc[item[key as string]] = item;
    return acc;
  }, {} as Indexed<T>);

export const updateArray = <T>(array: T[], item: T, opration: 'add' | 'remove') => {
  if (opration === 'add') {
    return [...array, item];
  } else {
    return array.filter((i) => !equal(i, item));
  }
};

export const concatOrUpdate = <T extends {}>(
  array: T[],
  newItem: T,
  compareFunction?: (item: T) => boolean,
) => {
  const index = array.findIndex((i) => (compareFunction ? compareFunction(i) : i === newItem));
  if (index !== -1) {
    return array.map((item, i) => {
      if (index !== i) {
        // This isn't the item we care about - keep it as-is
        return item;
      }

      // Otherwise, this is the one we want - return an updated value
      if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
        return newItem;
      } else {
        return {
          ...item,
          ...newItem,
        };
      }
    });
  } else {
    return [...array, newItem];
  }
};

export const arrayToOption = (op: string[]) => op.sort().map((c) => ({label: c, value: c}));

export const convertArrayOfCoordinatesToCoordinates = (array: number[]) => {
  return array ? array.slice().reverse().join(', ') : '';
};

export const convertCoordinatesStringToArray = (coordinates: string) => {
  return coordinates
    ? coordinates
        .split(', ')
        .reverse()
        .map((item) => Number(item))
    : [0, 0];
};

type ListIterator<T, TResult> = (value: T) => TResult;
type Many<T> = T | ReadonlyArray<T>;

export const flatMap = <T, R>(list: Array<T>, cb: ListIterator<T, Many<R>>): R[] => {
  return (list || []).reduce((acc, x) => acc.concat(cb(x)), [] as R[]);
};

const sort = (valueA: string | number, valueB: string | number) => {
  return typeof valueA === 'number' && typeof valueB === 'number'
    ? valueA - valueB
    : String(valueA).localeCompare(String(valueB));
};

export const sortByAsc =
  <T, K extends keyof T>(propName: K, defaultValue?: T[K]) =>
  (a: T, b: T) => {
    const valueA = _get(a, propName);
    const valueB = _get(b, propName);

    return sort(valueA, valueB);
  };
export const sortByDesc =
  <T, K extends keyof T>(propName: K, defaultValue?: T[K]) =>
  (a: T, b: T) => {
    const valueA = _get(a, propName, defaultValue);
    const valueB = _get(b, propName, defaultValue);

    return sort(valueB, valueA);
  };

export const sortByProperty =
  <T>(propName: keyof T, order: 'asc' | 'desc' = 'asc') =>
  (array: Array<T>) => {
    return array.sort(order === 'asc' ? sortByAsc(propName) : sortByDesc(propName));
  };

export const marketToOption = (market: Partial<Market>) => ({
  label: `${market.name} - ${market.code}`,
  value: market.id,
});

export const generalToOption = (value: string) => ({
  label: value,
  value: value,
});

export const binarySearch = (arr: any[], test: (value: any) => boolean) => {
  let [left, right] = [0, arr.length - 1];
  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (test(arr[mid])) right = mid - 1;
    else left = mid + 1;
  }
  // the first index where the test function returns true
  return left;
};
