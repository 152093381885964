import type {History} from 'history';
import * as _isEmpty from 'lodash.isempty';
import {all} from 'redux-saga/effects';

import {querySync, stringTransforms} from 'config/redux/modules/prism/lib';

import {LocationTypeWithoutText} from '../../../../../models/Location';
import {actions} from '../actions';

import type {Filters, InitialState} from '../reducers';
export default function* init(history: History<unknown>) {
  yield all([
    querySync('$limit', {
      history,
      selector: ({inventory: {pagination}}) => pagination.limit,
      action: actions.setPageLimit,
      stringToValue: parseInt,
    }),
    querySync('$skip', {
      history,
      selector: ({inventory: {pagination}}) => pagination.skip,
      action: actions.setPageSkip,
      stringToValue: parseInt,
      listeners: [actions.setFilters, actions.resetFilters],
    }),
    querySync('$sort', {
      history,
      selector: ({inventory: {pagination}}) => pagination.sort,
      action: actions.setPageSort,
      stringToValue: (s) => {
        let key = s;
        let value = 'asc';

        if (s.startsWith('-')) {
          key = s.replace('-', '');
          value = 'desc';
        }
        return {
          [key]: value,
        } as InitialState['pagination']['sort'];
      },
      valueToString: (v) => {
        const key = Object.keys(v)[0];
        return `${v[key] === 'desc' ? '-' : ''}${key}`;
      },
    }),
    querySync('searchTerm', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        searchTerm: stringTransforms.stringToValue(s),
      }),
      valueToString: (v) => stringTransforms.valueToString(v.searchTerm),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('assetStatus', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        panelStatus: split(s) as Filters['panelStatus'],
      }),
      valueToString: (v) => join(v.panelStatus),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('market', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        market: split(s) as Filters['market'],
      }),
      valueToString: (v) => join(v.market),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('curationStatus', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        curationStatus: split(s) as Filters['curationStatus'],
      }),
      valueToString: (v) => join(v.curationStatus),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('media', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => {
        if (!s) {
          return {media: null};
        }

        const [category, subCategory, names] = split(s, '^');

        return {
          media: {
            category: category,
            subCategory: subCategory,
            names: split(names),
          } as Filters['media'],
        };
      },
      valueToString: (v) => {
        if (_isEmpty(v.media)) {
          return '';
        }

        return [
          stringTransforms.valueToString(v.media?.category || ''),
          stringTransforms.valueToString(v.media?.subCategory || ''),
          join(v.media?.names || []),
        ].join('^');
      },
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('location', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => {
        if (!s) {
          return {location: null};
        }

        const [type, values] = split(s, '^');

        return {
          location: {
            type,
            typeKey: LocationTypeWithoutText[type],
            values: split(values),
          } as Filters['location'],
        };
      },
      valueToString: (v) => {
        if (_isEmpty(v.location)) {
          return '';
        }
        return [
          stringTransforms.valueToString(v.location?.type || ''),
          join(v.location?.values || []),
        ].join('^');
      },
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('ids', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        inventory: split(s) as Filters['inventory'],
      }),
      valueToString: (v) => join(v.inventory),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('format', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        format: s as Filters['format'],
      }),
      valueToString: (v) => v.format,
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('origin', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        dataOrigin: split(s) as Filters['dataOrigin'],
      }),
      valueToString: (v) => join(v.dataOrigin),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('prime', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        prime: s === 'true' ? true : null,
      }),
      valueToString: (v) => (v.prime ? 'true' : ''),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('acceptableImage', {
      history,
      selector: ({inventory: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        acceptableImage: s === 'yes' ? true : false,
      }),
      valueToString: (v) => {
        if (typeof v.acceptableImage === 'boolean') {
          return v.acceptableImage ? 'yes' : 'no';
        }
        return '';
      },
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
  ]);
}

const join = (arr: string[], separator: string = '|') =>
  arr ? arr.map((a) => stringTransforms.valueToString(a)).join(separator) : '';

const split = (text: string, separator: string = '|') =>
  (text || '')
    .split(separator)
    .filter((t) => !!t)
    .map((t) => stringTransforms.stringToValue(t));
