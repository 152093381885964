import type {Action} from 'redux-act';
import * as Sentry from '@sentry/browser';
import reduxCatch from 'redux-catch';

import {environment} from 'config';

const stateTransformer = (state) => {
  return {
    user: state.user,
    router: state.router,
  };
};

const captureException = (ex: Error) => {
  console.error(ex);
  return Sentry.captureException(ex);
};

const startTime = new Date();
const errorHandler = (error: Error, getState: Function, lastAction: Action<any>) => {
  Sentry.withScope((scope) => {
    scope.setExtra('lastAction', lastAction);
    scope.setExtra('session:duration', new Date().getTime() - startTime.getTime());
    scope.setExtra('state', stateTransformer(getState()));
    scope.setExtra('redux', true);

    captureException(error);
  });
};

export class SentryService {
  private static instance: SentryService;
  public middleware: ReturnType<typeof reduxCatch>;

  private constructor() {
    const {dsn, release} = environment.settings.sentry;
    if (!!process.env.ENVIRONMENT && dsn && release) {
      Sentry.init({
        dsn,
        release,
        environment: process.env.ENVIRONMENT as string,
      });
    }

    this.middleware = reduxCatch(errorHandler);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new SentryService();
    }

    return this.instance;
  }

  report = (error: Error | ErrorEvent | string, extra: {[index: string]: any} = {}) => {
    Sentry.withScope((scope) => {
      scope.setExtra('session:duration', new Date().getTime() - startTime.getTime());

      Object.keys(extra).forEach((key) => scope.setExtra(key, extra[key]));
      captureException(error as Error);
    });
  };
}
