import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import {beakynPurple} from './colors';

export const muiTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableTouchRipple: true,
    },
  },
  typography: {
    fontSize: 12.5,
    useNextVariants: true,
  },
  palette: {
    primary: {main: beakynPurple[500]},
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'rgba(0,0,0,0.4)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#4A4A4A',
      },
    },
    MuiButton: {
      root: {
        letterSpacing: -0.1,
      },

      containedPrimary: {
        backgroundImage: 'linear-gradient(to right, #9425b2 10%, #814ce6 100%)',
        transition: 'all 0.16s ease-in-out',
        boxShadow: '0 3px 8px 2px rgba(129, 76, 230, 0)',

        '&:hover': {
          boxShadow: '0 3px 8px 3px rgba(129, 76, 230, 0.2)',
        },
        '&$disabled': {
          opacity: 0.65,
          color: '#FFF !important',
          cursor: 'not-allowed !important',

          '&:hover': {
            transform: 'none',
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
