import {snakeCase} from 'lodash';

import {PanelSortOption} from '../../__generated__/globalTypes';
import {SortingUtil} from '../../models/ESSorting';
import {fetchQuery, gql, gqlRequest, queryClient} from '../graphql/CoreClient';

import type {CurationStatus, Format, PanelStatus} from '../../__generated__/globalTypes';
import type {Sorting} from '../../models/ESSorting';
import type {Panel} from 'models';
import type {Geometry} from '../../models/Geometry';
import type {
  SearchPanels,
  SearchPanels_searchPanels,
  SearchPanelsVariables,
} from './__generated__/SearchPanels';
import type {SearchPanelsIds, SearchPanelsIdsVariables} from './__generated__/SearchPanelsIds';
import type {UpdatePanel, UpdatePanelVariables} from './__generated__/UpdatePanel';
import type {CreatePanel, CreatePanelVariables} from './__generated__/CreatePanel';
import type {DeletePanel, DeletePanelVariables} from './__generated__/DeletePanel';
import type {GetPanel, GetPanel_getPanel, GetPanelVariables} from './__generated__/GetPanel';

const PANEL_FRAGMENT = gql`
  fragment PanelFragment on PanelGet {
    assets {
      id
      panelStatus
    }
    nearbyPanels {
      id
      panelStatus
    }
    acceptedFileExtensions
    acceptsRealTimeData
    alcoholTypeAllowed
    areaType {
      urban
      rural
    }
    cancellationClause
    comments1
    comments2
    computerName
    copyApproval
    copyChangeCharge
    createdAt
    curationNotes
    curationStatus
    currentAdvertizerNameList
    currentContractNumberList
    curatedRoadSegments
    dataOrigin
    digitalSize
    directionFromCrossStreet
    directionOfTraffic
    distanceUnit
    extensionAllowed
    facilityDescription
    facing
    flagmanRequired
    flipCount
    format
    geopathId
    geopathImpressions18
    gtReferenceNumber
    id
    idCache
    idSynonyms
    illuminated
    impressionsId
    impressionsIds
    imsUpdatedAt
    initialInstallIncluded
    installationCharge
    interiorLocation
    inventoryNumber
    leaseNumber
    location {
      bearing
      blockGroupId
      cbsa
      censusTractId
      center {
        coerce
        coordinates
        ignore_malformed
        ignore_z_value
        orientation
        type
      }
      centerGeoPoint {
        lat
        lon
      }
      country
      countryCode
      county
      crossStreet
      csa
      dma
      dmaCode
      dmaRank
      locality
      neighborhood
      place
      placeGroupId
      placeGroupName
      primaryStreet
      state
      stateCode
      viewshed
      zip
    }
    locationDescription
    loopLength
    mapImage {
      url
    }
    marketCode
    marketName
    marketingDescription
    marketingImageAcceptable
    curatedRoadSegments
    marketingImageUrl
    marketingInfo
    materialName
    mediaCategory
    mediaHeight
    mediaName
    mediaNameSynonyms
    mediaSubCategory
    mediaWidth
    name
    package {
      endDate
      id
      main
      name
    }
    panelStatus
    parRateNet
    permitId
    permitIdSynonyms
    permitNumber
    photoSheetUrl
    pixelHeight
    pixelWidth
    playTimeFrom
    playTimeTo
    primaryRead
    prime
    productionCharge
    productionForced
    productionMaterialCode
    progCPM
    progEnabled
    rateCardNet
    restrictions
    rideNumber
    secondaryRead
    sectorName
    sideOfHighway
    size
    snipeCharge
    specSheetUrl
    spotLength
    sqFootage
    stationId
    statusDate
    structureType
    suffix
    tabPanelId
    taxonomyId
    tenantId
    tertiaryRead
    totalDec
    triVision
    unitNumberDisplay
    updatedAt
    updatedBy
    viewshedId
    xUnit
  }
`;

const GET_PANEL = gql`
  query GetPanel($id: ID!) {
    getPanel(id: $id) {
      ...PanelFragment
    }
  }
  ${PANEL_FRAGMENT}
`;

export interface IGetPanelResponse extends GetPanel_getPanel {}

const PANELS_KEYS = {
  all: ['panels'] as const,
  lists: () => [...PANELS_KEYS.all, 'list'] as const,
  list: (filters) => [...PANELS_KEYS.lists(), {filters}] as const,
  listIds: (filters) => [...PANELS_KEYS.lists(), 'ids', {filters}] as const,
  details: () => [...PANELS_KEYS.all, 'detail'] as const,
  detail: (id) => [...PANELS_KEYS.details(), id] as const,
};

export async function getPanel(id: string): Promise<IGetPanelResponse> {
  const {getPanel: response} = await fetchQuery<GetPanel, GetPanelVariables>({
    queryKey: PANELS_KEYS.detail(id),
    queryDocument: GET_PANEL,
    queryVariables: {id},
    staleTime: 1000 * 60 * 10, // 10min
  });

  return response;
}

export interface ICreatePanelInput {
  inventoryNumber: string;
  mediaName: string;
  marketCode: string;
  curationStatus: CurationStatus;
  format: Format;
  panelStatus: PanelStatus;
  locationDescription: string;
  location: {
    bearing: number;
    primaryStreet?: string;
    crossStreet?: string;
    center: Geometry;
    viewshed: Geometry;
  };
  prime?: boolean;
  marketingImageUrl?: string;
  marketingImageAcceptable?: boolean;
  curatedRoadSegments?: boolean;
  gtReferenceNumber?: string;
  curationNotes?: string;
  facing?: string;
}

const CREATE_PANEL_MUTATION = gql`
  mutation CreatePanel($data: PanelCreateInput!) {
    createPanel(data: $data) {
      ...PanelFragment
    }
  }
  ${PANEL_FRAGMENT}
`;

export async function createPanel(data: ICreatePanelInput) {
  const {createPanel: response} = await gqlRequest<CreatePanel, CreatePanelVariables>(
    CREATE_PANEL_MUTATION,
    {data},
  );

  queryClient.setQueryData(PANELS_KEYS.detail(response.id), {getPanel: response});
  queryClient.invalidateQueries(PANELS_KEYS.lists(), {refetchActive: true});

  return response;
}

export interface IUpdatePanelInput
  extends Partial<
    Pick<
      ICreatePanelInput,
      | 'curationStatus'
      | 'curationNotes'
      | 'facing'
      | 'gtReferenceNumber'
      | 'prime'
      | 'marketingImageUrl'
      | 'marketingImageAcceptable'
      | 'location'
      | 'curatedRoadSegments'
    >
  > {
  id: string;
  mediaNameSynonyms?: string[];
  mediaCategory?: string;
  mediaSubCategory?: string;
}

const UPDATE_PANEL_MUTATION = gql`
  mutation UpdatePanel($id: ID!, $data: PanelUpdateInput!) {
    updatePanel(id: $id, data: $data) {
      ...PanelFragment
    }
  }
  ${PANEL_FRAGMENT}
`;

export async function updatePanel(panelInput: IUpdatePanelInput) {
  const {id, ...data} = panelInput;

  const {updatePanel: response} = await gqlRequest<UpdatePanel, UpdatePanelVariables>(
    UPDATE_PANEL_MUTATION,
    {id, data},
  );

  queryClient.setQueryData(PANELS_KEYS.detail(response.id), {getPanel: response});
  queryClient.invalidateQueries(PANELS_KEYS.lists(), {refetchActive: true});

  return response;
}

const DELETE_PANEL_MUTATION = gql`
  mutation DeletePanel($id: ID!) {
    deletePanel(id: $id) {
      id
    }
  }
`;

export async function deletePanel(id: string) {
  await gqlRequest<DeletePanel, DeletePanelVariables>(DELETE_PANEL_MUTATION, {id});

  queryClient.invalidateQueries(PANELS_KEYS.detail(id));
  queryClient.invalidateQueries(PANELS_KEYS.lists(), {refetchActive: true});

  return id;
}

interface ISearchPanelsProps {
  limit?: number;
  skip?: number;
  query?: string;
  sort?: Sorting<Partial<Panel>>;
}

function parseSearchPanelsProps(params: ISearchPanelsProps) {
  const sort = SortingUtil.toArray<Partial<Panel>>(params.sort).map<PanelSortOption>(
    (sortValue) => PanelSortOption[snakeCase(sortValue).toUpperCase()],
  );

  if (!params.sort?._id) {
    sort.push(PanelSortOption.ID_ASC);
  }

  return {
    sort,
    ...(Number.isInteger(params.limit) && {limit: params.limit}),
    ...(Number.isInteger(params.skip) && {skip: params.skip}),
    ...(!!params.query && {query: params.query}),
  };
}

const SEARCH_PANELS_IDS = gql`
  query SearchPanelsIds($skip: Int, $limit: Int, $query: String, $sort: [PanelSortOption!]) {
    searchPanels(skip: $skip, limit: $limit, query: $query, sort: $sort) {
      total
      data {
        id
      }
    }
  }
`;

export async function searchPanelsIds(params: ISearchPanelsProps) {
  const queryVariables = parseSearchPanelsProps(params);

  const {searchPanels: response} = await fetchQuery<SearchPanelsIds, SearchPanelsIdsVariables>({
    queryKey: PANELS_KEYS.listIds(params),
    queryDocument: SEARCH_PANELS_IDS,
    queryVariables,
    staleTime: 1000 * 60 * 10, // 10min
  });

  return response;
}

const SEARCH_PANELS = gql`
  query SearchPanels($skip: Int, $limit: Int, $query: String, $sort: [PanelSortOption!]) {
    searchPanels(limit: $limit, skip: $skip, query: $query, sort: $sort) {
      total
      data {
        id
        marketCode
        marketName
        inventoryNumber
        curationStatus
        panelStatus
        mediaName
        updatedBy
        updatedAt
        locationDescription
        mapImage {
          url
        }
        gtReferenceNumber
        dataOrigin
      }
    }
  }
`;

export interface IPanelList extends SearchPanels_searchPanels {}

export async function searchPanels(params: ISearchPanelsProps): Promise<IPanelList> {
  const queryVariables = parseSearchPanelsProps(params);

  const {searchPanels: response} = await fetchQuery<SearchPanels, SearchPanelsVariables>({
    queryKey: PANELS_KEYS.list(params),
    queryDocument: SEARCH_PANELS,
    queryVariables,
    staleTime: 1000 * 60 * 10, // 10min
  });

  return response;
}
