import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {gql} from 'graphql-request';

import {CurationStatus, PanelStatus} from '../../../../../__generated__/globalTypes';
import {gqlRequest} from '../../../../../services/graphql/CoreClient';

import type {LocationType} from '../../../../../__generated__/globalTypes';
import type {
  SearchLocationSuggestions,
  SearchLocationSuggestionsVariables,
} from './__generated__/SearchLocationSuggestions';

interface SearchLocationSuggestionsParams {
  searchTerm: string;
  locationType: LocationType;
  panelStatus: PanelStatus[];
  curationStatus: CurationStatus[];
}
interface UseLocationsSuggestionsProps {
  locationType: LocationType;
  searchTerm?: string;
  panelStatus?: PanelStatus[];
  curationStatus?: CurationStatus[];
}

interface Option {
  label: string;
  value?: string;
  options?: Option[];
}

const LOCATION_SUGGESTIONS_KEYS = {
  all: ['locationSuggestions'] as const,
  getKey: (filters) => [...LOCATION_SUGGESTIONS_KEYS.all, {filters}] as const,
};

const SEARCH_LOCATIONS_SUGGESTIONS = gql`
  query SearchLocationSuggestions(
    $searchTerm: String
    $locationType: LocationType!
    $panelStatus: [PanelStatus!]
    $curationStatus: [CurationStatus!]
  ) {
    suggestLocations(
      searchTerm: $searchTerm
      locationType: $locationType
      panelStatus: $panelStatus
      curationStatus: $curationStatus
    ) {
      data {
        name
        items
      }
    }
  }
`;

async function searchLocationSuggestions(params: SearchLocationSuggestionsParams) {
  if (!params.locationType) return [];

  const {suggestLocations: response} = await gqlRequest<
    SearchLocationSuggestions,
    SearchLocationSuggestionsVariables
  >(SEARCH_LOCATIONS_SUGGESTIONS, params);

  return response.data;
}

export function useLocationSuggestions({
  locationType,
  searchTerm = '',
  panelStatus = [PanelStatus.ACTIVE],
  curationStatus = [CurationStatus.PUBLISHED],
}: UseLocationsSuggestionsProps) {
  const variables = {
    searchTerm,
    locationType,
    panelStatus,
    curationStatus,
  };

  const {
    data = [],
    isLoading,
    error,
  } = useQuery(
    LOCATION_SUGGESTIONS_KEYS.getKey(variables),
    () => searchLocationSuggestions(variables),
    {staleTime: 1000 * 60 * 30}, // 30min
  );

  const options = useMemo(
    () =>
      data.map<Option>(({name, items}) => {
        if (items?.length) {
          return {
            label: name,
            options: items.map((itemName) => ({label: itemName, value: itemName})),
          };
        }

        return {
          label: name,
          value: name,
        };
      }),
    [data],
  );

  return {
    data,
    options,
    isLoading,
    error,
  };
}
