import type {History} from 'history';
import * as _isEmpty from 'lodash.isempty';

import {querySync, stringTransforms} from '../../prism/lib';
import {actions} from '../actions';
import {join, split, UrlParams} from './';

import type {RootState} from 'config/redux/rootReducer';

import type {Filters} from '../reducers';
export const mediaValueToString = (media: RootState['panels']['filters']['media']) => {
  if (_isEmpty(media) || (!media.category && !media.subCategory && _isEmpty(media.names))) {
    return '';
  }

  return [
    stringTransforms.valueToString(media?.category || ''),
    stringTransforms.valueToString(media?.subCategory || ''),
    join(media?.names || []),
  ].join('^');
};

export const mediaQuerySync = (history: History<unknown>) =>
  querySync(UrlParams.media, {
    history,
    selector: ({panels: {filters}}) => filters,
    action: actions.concatFilters,
    stringToValue: (stringValue) => {
      if (!stringValue) {
        return {media: null};
      }

      const [category, subCategory, names] = stringValue
        .split('^')
        .map((value) => stringTransforms.stringToValue(value));

      return {
        media: {
          category: category,
          subCategory: subCategory,
          names: split(names),
        } as Filters['media'],
      };
    },
    valueToString: (v) => mediaValueToString(v.media),
    listeners: [actions.setFilters, actions.resetFilters],
    defaultValue: {
      media: null as Filters['media'],
    },
  });
