import type {History} from 'history';
import {eventChannel} from 'redux-saga';
import {call, cancel, spawn, take} from 'redux-saga/effects';

import type {CallEffectFn} from 'redux-saga/effects';
export function* sagaRouter(
  history: History,
  routes: {[index: string]: CallEffectFn<(h?: History) => any>},
) {
  const channel = yield call(locationChange, history);
  let task = yield routes[history.location.pathname] &&
    spawn(routes[history.location.pathname], history);

  while (true) {
    const loc = yield take(channel);

    yield !!task && cancel(task);

    if (!routes[loc.pathname]) continue;

    task = yield spawn(routes[loc.pathname], history);
  }
}

function locationChange(history: History) {
  return eventChannel((emmitter) =>
    history.listen((loc, action) => {
      if (action !== 'REPLACE') {
        emmitter(loc);
      }
    }),
  );
}
