import type {FilterColorChipProps} from '@onsmart/ui-kit';
import {FilterColorChip, filterColorChipStylesDecorator} from '@onsmart/ui-kit';
import {truncateArray} from 'utils/string';

import {toUSLocale} from 'utils/number';

type Props = FilterColorChipProps<string[]>;

class Chip extends FilterColorChip<Props, string[]> {
  getLabel(value: string[]) {
    const truncateSize = 2;
    const leftFilters = value.length - truncateSize;
    const truncate = (leftFilters > 1 ? truncateArray(value, truncateSize) : value).join(', ');

    if (leftFilters > 1) {
      return `ID: ${truncate} and ${toUSLocale(leftFilters)} more`;
    } else {
      return `ID: ${truncate}`;
    }
  }
}

export default filterColorChipStylesDecorator(Chip);
