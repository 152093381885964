import React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import {MultiSelectList} from '@onsmart/ui-kit';
import {useMediaSynonyms} from 'hooks';

import type {Option} from '@onsmart/ui-kit';

import type {MediaFilter} from 'models/MediaType';

import type {WithStyles} from '@material-ui/core/styles/withStyles';

const styles = () =>
  createStyles({
    selectedWithValues: {
      paddingTop: 0,
    },
  });

type Styles = WithStyles<typeof styles>;

const useList = (selected: Props['selected']) => {
  const {category, subCategory, names} = selected || {};
  const {mediaSynonymsOptions, isLoading} = useMediaSynonyms({
    mediaCategory: category,
    mediaSubCategory: subCategory,
  });

  const options = React.useMemo(() => {
    return mediaSynonymsOptions.filter((m) => !(names || []).includes(m.value));
  }, [names, mediaSynonymsOptions]);

  const valueOptions = React.useMemo(() => {
    return names?.map((n) => ({value: n, label: n}));
  }, [names]);

  return {isLoading, options, valueOptions};
};

interface Props extends Styles {
  selected: Partial<MediaFilter>;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  onChange: (names: string[]) => void;
}

const List = (props: Props) => {
  const {classes, selected, onMenuOpen, onMenuClose, onChange} = props;

  const {isLoading, options, valueOptions} = useList(selected);

  const handleChange = React.useCallback(
    (opts: Option[]) => {
      const names = opts.map((m) => m.label);
      onChange(names);
    },
    [onChange],
  );

  return (
    <MultiSelectList
      classes={{selectWithValues: classes.selectedWithValues}}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      label="Media Type"
      options={options}
      isLoading={isLoading}
      values={valueOptions}
      onChange={handleChange}
    />
  );
};

export default withStyles(styles)(List);
