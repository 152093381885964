import {createAction} from 'redux-act';

import type {InitialState} from './reducers';

export const actions = {
  fetchData: createAction<InitialState['data']['id']>('assetForm/DATA_FETCH_REQUESTED'),
  setData: createAction<InitialState['data']>('assetForm/DATA_FETCH_SUCCEEDED'),
  showAssetFormLoading: createAction('app/SHOW_ASSET_FORM_LOADING'),
  hideAssetFormLoading: createAction('app/HIDE_ASSET_FORM_LOADING'),
};
