import {createAction} from 'redux-act';

import type {InitialState} from './reducers';

export const actions = {
  setPageSort: createAction<InitialState['pagination']['sort']>('showings/SET_SORT'),
  setPageLimit: createAction<InitialState['pagination']['limit']>('showings/SET_PAGE_LIMIT'),
  setPageSkip: createAction<InitialState['pagination']['skip']>('showings/SET_PAGE_SKIP'),
  setFilters: createAction<InitialState['filters']>('showings/SET_FILTERS'),
  concatFilters: createAction<Partial<InitialState['filters']>>('showings/CONCAT_FILTERS'),
  resetFilters: createAction('showings/RESET_FILTERS'),
};
