import type {
  FilterColorChipProps} from '@onsmart/ui-kit';
import {
  FilterColorChip,
  filterColorChipStylesDecorator,
} from '@onsmart/ui-kit';

import {truncateArray} from 'utils/string';

interface Props extends FilterColorChipProps<string[]> {}

class Chip extends FilterColorChip<Props, string[]> {
  getLabel(markets: string[]) {
    const truncateSize = 2;
    const leftFilters = markets.length - truncateSize;
    const truncate = (leftFilters > 1 ? truncateArray(markets, truncateSize) : markets).join(', ');

    if (leftFilters > 1) {
      return `Market: ${truncate} and ${leftFilters} more`;
    } else {
      return `Market: ${truncate}`;
    }
  }
}

export default filterColorChipStylesDecorator(Chip);
