import {isEmpty} from 'lodash';
import {call} from 'redux-saga/effects';

import {ActivityQueue} from 'utils/models/activityQueue';

import type {
  ActivityData,
  TopicType,
  ActivityModel,
  ActivityType,
} from 'utils/models/activityQueue';
import type {Action} from 'redux-act';

export interface ICreateActivityRecord {
  model: ActivityModel;
  topic: TopicType;
  data: ActivityData;
  type: ActivityType;
}

export function* createActivityRecordSaga(action: Action<ICreateActivityRecord>) {
  try {
    const activityData = action.payload;
    if (!isEmpty(activityData)) {
      const {topic, data, model, type} = activityData;

      const activityQueue = ActivityQueue.getInstance();
      activityQueue.queue(topic, data, model);

      yield call([activityQueue, 'createActivityByTopic'], topic, type);
    }
  } catch (error) {
    console.error(error);
  }
}
