import * as React from 'react';
import {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {AsyncAutocomplete, AutocompleteSize, PopperContentSection} from '@onsmart/ui-kit';
import classNames from 'classnames';

import {debounce} from 'utils/promise';

import {styles} from './styles';

import type {Option} from '@onsmart/ui-kit';
import type {Styles} from './styles';
const toOption = (op: string) => ({label: op, value: op});

export interface Props extends Styles {
  selected: string[];
  onChange: (filter: string[]) => void;
  loadSuggestions: (search: string) => Promise<string[]>;
}

class Popper extends Component<Props> {
  debouncedLoad: () => Promise<unknown>;
  private _isMounted: boolean;

  constructor(props: Props) {
    super(props);
    this.debouncedLoad = debounce(this.loadMore, 500);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadMore = async (term = '') => {
    const ids = await this.props.loadSuggestions(term);

    const nonUsedIds = this.removeUsedKeywords(ids);
    return nonUsedIds.map(toOption);
  };

  removeUsedKeywords = (allOptions: string[]) => {
    const {selected} = this.props;
    const ids = selected || ([] as string[]);
    return allOptions.filter((id) => !ids.includes(id));
  };

  appendToSelected = (ids: string[]) => {
    const {onChange, selected = []} = this.props;
    const newSelected = new Set([...selected, ...ids]);
    onChange(Array.from(newSelected));
  };

  handleSelectChange = (value: Option) => {
    if (value) {
      const {selected = []} = this.props;
      this.props.onChange([...selected, value.label]);
    }
  };

  handleImportClick = () => {
    this.setState({batchMode: true});
  };

  handleCancelBatchClick = () => {
    this.setState({batchMode: false, progress: 1, batchLoading: false});
  };

  handleBatchIdsChange = (text: string) => {
    this.setState({batchIds: text});
  };

  render() {
    const {classes, selected} = this.props;

    return (
      <PopperContentSection
        className={classNames(classes.sticky, {
          [classes.stickyWithValues]: selected && !!selected.length,
        })}
      >
        <AsyncAutocomplete
          key={selected.length}
          size={AutocompleteSize.small}
          label="Search by Inventory ID"
          placeholder=""
          onChange={this.handleSelectChange}
          value={null}
          defaultOptions
          cacheOptions
          autoFocus
          loadOptions={this.debouncedLoad}
        />
      </PopperContentSection>
    );
  }
}

export default withStyles(styles)(Popper);
