import type {FunctionComponent} from 'react';
import React from 'react';
import {filterColorChipStylesDecorator, RadioFilterChip} from '@onsmart/ui-kit';

import type {RadioFilterChipProps} from '@onsmart/ui-kit';
interface Props extends RadioFilterChipProps {}

const Chip: FunctionComponent<Props> = (props) => {
  return <RadioFilterChip {...props} />;
};

Chip.defaultProps = {
  chipPrefix: 'Acceptable Marketing Image:',
};

export default filterColorChipStylesDecorator(Chip);
