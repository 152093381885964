export const fetchCSVPanelDataSource = [
  'marketCode',
  'marketName',
  'inventoryNumber',
  'curationStatus',
  'panelStatus',
  'mediaName',
  'locationDescription',
  'location.centerGeoPoint.lat',
  'location.centerGeoPoint.lon',
  'location.bearing',
  'updatedBy',
  'updatedAt',
  'mapImage.url',
];
