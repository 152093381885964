import {call, put, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global';

import {getAssetWithParent} from '../../../../services/AssetRestService';
import {actions} from './actions';

import type {SagaReturnType} from '../../../../utils/sagas';
function* fetchAssetFormSaga(action: any) {
  try {
    yield put(globalActions.showLoading());
    yield put(actions.showAssetFormLoading());

    const asset: SagaReturnType<typeof getAssetWithParent> = yield call(
      getAssetWithParent,
      action.payload,
    );

    yield put(actions.setData(asset));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.hideAssetFormLoading());
    yield put(globalActions.hideLoading());
  }
}

export function* assetFormSaga() {
  yield takeLatest(actions.fetchData, fetchAssetFormSaga);
}
