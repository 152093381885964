import {isEmpty} from 'lodash';
import { environment } from '../../../../config/environment';
import { PanelStatus } from '../../../../__generated__/globalTypes';

interface GetPanelMarketingImageOrDefaultProps {
  panelId: string
  assets: { 
    id: string
    panelStatus: PanelStatus
  }[]
};

export function getDefaultMarketingImage({ panelId, assets = [] }: GetPanelMarketingImageOrDefaultProps) {
  const activeAsset = assets.find(asset => asset.panelStatus === PanelStatus.ACTIVE);

  if(isEmpty(activeAsset) || !panelId) return null;

  const { id } = activeAsset;

  const imagesUrl = environment.settings.apiUrl.images;
  const [marketCode, suffix] = id.split(panelId);

  return `${imagesUrl}?t=photo&m=${marketCode}&p=${panelId}${suffix}`;
};

export function processMarketingImageUrl(marketingImageUrl: string) {
  if (!marketingImageUrl) return null;

  const imageHeight = 360;
  const url = marketingImageUrl.includes('?') 
    ? `${marketingImageUrl}&h=${imageHeight}`
    : marketingImageUrl;

  return url;
};
