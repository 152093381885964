import {environment} from 'config';

export const parse = (url: string): HTMLAnchorElement => {
  const parser = document.createElement('a');
  parser.href = url;
  return parser;
};

export const urlParamsToObject = (params: string): any => {
  return decodeURIComponent(params)
    .substr(1)
    .split('&')
    .reduce((accu, param) => {
      const [key, value] = param.split('=');

      if (accu[key]) {
        accu[key] = Array.isArray(accu[key]) ? [...accu[key], value] : [accu[key], value];

        return accu;
      }

      accu[key] = value;

      return accu;
    }, {});
};

export const getDomain = () => {
  if (window.location.hostname === 'localhost') {
    return 'localhost';
  }

  const regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}:?[0-9]{0,5}$');
  return getFirstHostName(regexParse);
};

export const getSubdomain = () => {
  if (window.location.hostname === 'localhost') {
    return '';
  }

  const regexParse = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
  return getFirstHostName(regexParse);
};
const getFirstHostName = (reg: RegExp) => (reg.exec(window.location.hostname) || [''])[0];

export const getPrefix = () => {
  const subdomain = getSubdomain();
  if (subdomain === 'mad.') {
    return '';
  }

  return subdomain;
};

export const getAccountLink = () => {
  let link = '';

  if (environment.currentType !== 'production') {
    if (window.location.hostname === 'localhost') {
      const protocol = window.location.protocol;
      link = `${protocol}//localhost`;
    } else {
      link = generateLink('account');
    }

    return `${link}:9000`;
  } else {
    return generateLink('account');
  }
};

export const generateLink = (app: string) => {
  const prefix = getPrefix();
  const domain = getDomain();
  const protocol = window.location.protocol;

  return `${protocol}//${prefix}${app}.${domain}`;
};
