import booleanWithin from '@turf/boolean-within';
import * as turf from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';

type FeatPoint = GeoJSON.Feature<GeoJSON.Point>;
type FeatLine = GeoJSON.Feature<GeoJSON.LineString>;
type FeatPolyg = GeoJSON.Feature<GeoJSON.Polygon>;

const LineWithinPoly = (line: FeatLine, polygon: FeatPolyg) => {
  const intersect = lineIntersect(line, polygon);
  const intersectLength = intersect.features.length;
  const within = booleanWithin(line, polygon);

  if (intersectLength > 0 || within) {
    return true;
  }
};

export const clipGeoJSON = (features: GeoJSON.Feature<GeoJSON.Geometry>[]) => {
  const findBy = (search: GeoJSON.Geometry['type']) => ({geometry: {type}}) => type === search;

  const nodes = features.filter(findBy('Point')) as FeatPoint[];
  const lines = features.filter(findBy('LineString')) as FeatLine[];
  const viewshed = features.find(findBy('Polygon')) as FeatPolyg;

  const result = clipRoads(nodes, lines, viewshed);

  const panel = features.find(({properties: p}) => p!.type === 'panel');

  return {
    ...result,
    features: [panel, viewshed, ...result.features],
  };
};

export const clipRoads = (nodes: FeatPoint[], lines: FeatLine[], polygon: FeatPolyg) => {
  // Throw an error if any of the features are missing.
  // TODO: validate that are features
  if (!nodes) {
    throw new Error('Features missing: nodes.');
  }
  if (!lines) {
    throw new Error('Features missing: lines.');
  }
  if (!polygon) {
    throw new Error('Features missing: polygon.');
  }

  // Build FeatureCollection with all the input data.
  const input = turf.featureCollection<GeoJSON.Point | GeoJSON.LineString | GeoJSON.Polygon>([
    ...nodes,
    ...lines,
    polygon,
  ]);

  // Cache intersecting ways
  const intersectingWays = {
    ...input,
    features: input.features
      .filter((feature) => feature.geometry.type === 'LineString')
      .filter((feature) => feature.geometry.coordinates.length)
      .filter((feature) => LineWithinPoly(feature as FeatLine, polygon)),
  } as GeoJSON.FeatureCollection<GeoJSON.LineString>;

  // Init intersecting nodes feature collection
  const output = turf.featureCollection([]);

  intersectingWays.features.forEach((way) => {
    const {
      geometry: {coordinates: coords},
      properties: props,
    } = way;

    const {nodes = []} = props!;

    /**
     * For each node on the way, create a new line string
     * segment using the current node and the previous node.
     */
    nodes.forEach((_, index) => {
      if (index > 0) {
        const segmentNodes = [nodes[index - 1], nodes[index]];
        const line = turf.lineString([coords[index - 1] as number[], coords[index] as number[]], {
          ...way.properties,
          nodes: segmentNodes,
        });
        const within = LineWithinPoly(line, polygon);

        if (within) {
          output.features.push(line);
        }
      }
    });
  });

  // Add nodes to feature Collection
  const clippedNodesIds = output.features.map((feature) => feature.properties!.nodes).flat();
  const clippedNodes = nodes.filter((node) => clippedNodesIds.includes(node.properties!.id));

  // Merge all the features
  output.features = [...output.features, ...clippedNodes];

  return output;
};
