import {createAction} from 'redux-act';

import type {InitialState} from './reducers';

export const actions = {
  fetchData: createAction('markets/FETCH_DATA'),
  fetchDataSuccess: createAction<{data: InitialState['data']; total: InitialState['total']}>(
    'markets/FETCH_DATA_SUCCESS',
  ),
  fetchDataFail: createAction('markets/FETCH_DATA_FAIL'),

  setPageSort: createAction<InitialState['pagination']['sort']>('markets/SET_SORT'),
  setFilters: createAction<InitialState['filters']>('markets/SET_FILTERS'),
  concatFilters: createAction<Partial<InitialState['filters']>>('markets/CONCAT_FILTERS'),
  resetFilters: createAction('markets/RESET_FILTERS'),
};
