import { useState, useEffect, useMemo, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { formatLocationTypeLabel } from '../utils/locationTypeFormatter';
import { LocationType } from '../../../../../__generated__/globalTypes';
import { LocationTypeWithoutText } from '../../../../../models/Location';

const INITIAL_LOCATION_FILTER = {
  type: LocationType.ZIP_CODE, 
  typeKey: LocationTypeWithoutText.ZIP_CODE,
  values: []
};

interface UseFilterOptionsProps {
  type: LocationType
  values: string[]
};

interface FilterValue extends UseFilterOptionsProps {
  typeKey: string
};

interface Option {
  value: string
  label: string
};

export function useFilterOptions(value: UseFilterOptionsProps) {
  const [filterValue, setFilterValue] = useState<FilterValue>({} as FilterValue);

  useEffect(() => {
    let valueSelected = INITIAL_LOCATION_FILTER;

    if(!isEmpty(value)) {
      valueSelected = {
        type: value.type,
        typeKey: LocationTypeWithoutText[value.type],
        values: value.values ?? [],
      };
    }

    setFilterValue(valueSelected);
  }, [value]);

  const setLocationTypeOption = useCallback((option: Option) => {
    const type = option?.value as LocationType ?? null;

    setFilterValue({
      type,
      typeKey: LocationTypeWithoutText[type],
      values: []
    });
  }, []);

  const setLocationValuesOptions = useCallback((options: Option[]) => {
    const optionValues = options
      .filter((option) => !isEmpty(option))
      .map((option) => option.label);

    const values = Array.from(new Set(optionValues));

    setFilterValue({ ...filterValue, values });
  }, [filterValue]);

  const locationTypeOptionSelected = useMemo(() => {
    if(!filterValue.type) return undefined;
      
    return {
      label: formatLocationTypeLabel(filterValue.type),
      value: filterValue.type
    };
  }, [filterValue.type]);

  const locationValuesOptionsSelected = useMemo(() => (
    filterValue.values?.map(value => ({ label: value, value }))
  ), [filterValue.values]);

  const hasLocationValuesSelected = useMemo(() => (
    filterValue.type && !isEmpty(filterValue.values)
  ), [filterValue.type, filterValue.values]);

  return { 
    hasLocationValuesSelected,
    locationTypeOptionSelected, 
    locationValuesOptionsSelected,
    setLocationTypeOption,
    setLocationValuesOptions,
    filterValue,
  };
};
