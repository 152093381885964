import type {History} from 'history';
import {ShowingStatus} from '__generated__/globalTypes';
import {all} from 'redux-saga/effects';

import {querySync, stringTransforms} from '../../prism/lib';
import {actions} from '../actions';

export default function* init(history: History<unknown>) {
  yield all([
    querySync('$limit', {
      history,
      selector: ({showings: {pagination}}) => pagination.limit,
      action: actions.setPageLimit,
      stringToValue: parseInt,
    }),
    querySync('$skip', {
      history,
      selector: ({showings: {pagination}}) => pagination.skip,
      action: actions.setPageSkip,
      stringToValue: parseInt,
      listeners: [actions.setFilters, actions.resetFilters],
    }),
    querySync('$sort', {
      history,
      selector: ({showings: {pagination}}) => pagination.sort,
      action: actions.setPageSort,
    }),
    querySync('searchTerm', {
      history,
      selector: ({showings: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        searchTerm: stringTransforms.stringToValue(s),
      }),
      valueToString: (v) => stringTransforms.valueToString(v.searchTerm),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('status', {
      history,
      selector: ({showings: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s = '') => {
        const strVal = stringTransforms.stringToValue(s)
        const status = strVal.split(',').map<ShowingStatus>(val => ShowingStatus[val])

        return { status }
      },
      valueToString: (v) => {
        return !!v.status?.length ? stringTransforms.valueToString(v.status.join(',')) : ''
      },
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
  ]);
}
