import * as _capitalize from 'lodash.capitalize';

import {compose} from './functional';

export const isSortDescending = (property: string) => property.indexOf('-') === 0;

export const getSortProperty = (sort: string) => (isSortDescending(sort) ? sort.substr(1) : sort);

export const replace = (oldText: string | RegExp) => (newText: string) => (text: string) => {
  if (text) {
    return text.replace(oldText, newText);
  }
};

export const pascalCase = (text: string) => {
  if (text) {
    return text.split(' ').map(_capitalize).join(' ');
  }
};

export const lowerCase = (text: string) => !!text && text.toLowerCase();

export const removeDashAndToPascalCase = compose(pascalCase, replace(/-/g)(' '));

export const truncate = (text: string, maxSize: number) => {
  const ellipsis = '...';

  if (text.length > maxSize) {
    return text.substr(0, maxSize - ellipsis.length) + ellipsis;
  } else {
    return text;
  }
};

export const truncateArray = (value: string[], truncateSize: number) => {
  let returnArray = [] as string[];
  if (value.length > truncateSize) {
    for (let index = 0; index < truncateSize; index++) {
      returnArray.push(`${value[index]}`);
    }
  } else {
    return value;
  }
  return returnArray;
};

export const toBoolean = (text?: string) => {
  if (text) {
    const lowerCasedText = text.toLocaleLowerCase();
    return lowerCasedText === 'true' || lowerCasedText === 'yes';
  }
};

export const toUpperCase = (item: string) => item.toUpperCase();
