import turfDestination from '@turf/destination';
import {isEmpty} from 'lodash';
import * as get from 'lodash.get';

import {environment} from 'config';

import type {Location, Panel} from 'models';

export const parseLocationData = (panel: Panel) => ({
  viewshed: get(panel, 'location.viewshed')
    ? JSON.parse(JSON.stringify(panel.location.viewshed))
    : undefined,
  center: get(panel, 'location.center')
    ? JSON.parse(JSON.stringify(panel.location.center))
    : undefined,
  bearing: get(panel, 'location.bearing')
    ? JSON.parse(JSON.stringify(panel.location.bearing))
    : undefined,
});

export function createImageMapUrl({bearing, center, viewshed}: Location) {
  if (isEmpty(center)) return null;

  // Extract center coordinates to valid google string
  const centerCoordinates = `${center.coordinates[1]},${center.coordinates[0]}`;

  // Create basic image URL with center and marker
  let imageUrl =
    `${environment.settings.googleMaps.url}/staticmap?center=${centerCoordinates}` +
    `&zoom=17&size=640x640&maptype=roadmap&markers=color:red|label:P|${centerCoordinates}`;

  // If bearing is available calculate bearing center with turf and draw line
  if (bearing) {
    const bearingFeature = turfDestination(center.coordinates, 0.05, bearing, 'kilometers');
    const bearingCoordinates = bearingFeature.geometry.coordinates.reverse().join();

    imageUrl = `${imageUrl}&path=color:0xff0000ff|weight:3|${centerCoordinates}|${bearingCoordinates}`;
  }

  // If viewshed.coordinates is available parse array and draw polygin
  if (viewshed && viewshed.coordinates && viewshed.coordinates.length) {
    viewshed.coordinates.forEach((coordinates) => {
      const coords = viewshed.type === 'MultiPolygon' ? coordinates[0] : coordinates;
      const viewshedCoordinates = coords
        .filter((coord) => Array.isArray(coord))
        .map((coord) => `${coord[1]}, ${coord[0]}`)
        .join('|');

      imageUrl = `${imageUrl}&path=color:0x7f20d0|fillcolor:0x7f20d033|weight:3|${viewshedCoordinates}`;
    });
  }

  // Add API Key to image URL
  imageUrl = `${imageUrl}&key=${environment.settings.googleMaps.token}`;

  return imageUrl;
}
