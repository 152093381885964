import React, {PureComponent} from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import {Breadcrumbs, BreadcrumbsItem, Layout as UiKitLayout} from '@onsmart/ui-kit';
import {SnackbarProvider} from 'notistack';

import {environment} from 'config';
import {Role} from 'models';
import {SentryService} from 'services/SentryService';
import 'styles/components/Layout.scss';

import {HasRole} from '../HasRole';
import SnackbarStack from '../SnackbarStack';
import {AppDrawerContent} from './AppDrawerContent';
import {MainNavLeft} from './MainNavLeft';
import {MainNavRight} from './MainNavRight';
import {mapDispatchToProps, mapStateToProps} from './selectors';
import OutfrontLogo from './SvgIcons/OutfrontLogo';

import type {MapDispatchToProps, MapStateToProps} from './selectors';
import type {WithStyles} from '@material-ui/core/styles/withStyles';
import type {MouseEvent} from 'react';
export interface LayoutProps extends MapStateToProps, MapDispatchToProps {}

const logoStyle = {
  marginLeft: 15,
};

interface State {
  drawerOpen: boolean;
}

class LayoutComponent extends PureComponent<LayoutProps, State> {
  static defaultProps: Partial<any> = {
    activeNavLink: '',
  };

  state: State = {
    drawerOpen: false,
  };

  componentDidMount() {
    document.body.scrollTop = 0;
  }

  componentDidCatch(error: Error) {
    SentryService.getInstance().report(error, {react: true});
  }

  render() {
    const {children, pageTitle, roles} = this.props;
    const {drawerOpen} = this.state;
    const {app} = environment.settings;

    return (
      <UiKitLayout
        drawerOpen={drawerOpen}
        drawerTitle={app.name}
        onDrawerOpen={this.handleDrawerOpen}
        onDrawerClose={this.handleDrawerClose}
        mainNavLeftElement={this.renderMainNavLeft()}
        mainNavRightElement={this.renderMainNavRight()}
        drawerMenu={
          <AppDrawerContent
            activeNavLink={this.props.activeNavLink}
            onMenuClick={this.handleOnMenuClick}
            roles={roles}
          />
        }
      >
        <Helmet>
          <title>{`${pageTitle} | ${app.name}`}</title>
        </Helmet>

        {children}

        <Snackbar />
      </UiKitLayout>
    );
  }

  renderMainNavLeft = () => {
    const {pageTitle} = this.props;

    return (
      <>
        <HasRole hideForRole={Role.dmpGuest}>
          <MainNavLeft serviceOptions={this.props.serviceOptions} />
        </HasRole>
        <OutfrontLogo style={logoStyle} />
        <Breadcrumbs>
          <BreadcrumbsItem>{pageTitle}</BreadcrumbsItem>
        </Breadcrumbs>
      </>
    );
  };

  renderMainNavRight = () => {
    const {loading, user, openSettingsPage} = this.props;

    return (
      <MainNavRight
        loading={loading}
        user={user}
        onClickLogoutButton={this.logout}
        onAccountClick={openSettingsPage}
      />
    );
  };

  handleOnMenuClick = () => {
    this.handleDrawerClose();
  };

  handleDrawerClose = () => {
    this.setState({drawerOpen: false});
    this.forceUpdate();
  };

  handleDrawerOpen = () => {
    this.setState({drawerOpen: true});
  };

  logout = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.logout();
  };
}

const styles = () =>
  createStyles({
    snackbar: {
      left: 24,
      right: 'auto',
    },
  });

const Snackbar = withStyles(styles)((props: WithStyles<typeof styles>) => (
  <SnackbarProvider
    classes={{anchorOriginBottomLeft: props.classes.snackbar}}
    maxSnack={10}
    autoHideDuration={6000}
  >
    <SnackbarStack />
  </SnackbarProvider>
));

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
