export interface RoadPanel {
  id: string;
  data: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  center: {lat: number; lon: number};
  updatedAt?: string;
}

export const getEmptyRoad = (): RoadPanel => ({
  id: '',
  center: {lat: 0, lon: 0},
  data: {type: 'FeatureCollection', features: []},
});

export type RoadProcessed = RoadPanel & {
  alertId?: number;
};

export const getRoadProcessed = (payload: RoadPanel) => {
  const {data, ...roadProcessed} = payload as RoadProcessed;

  const sortedFeatures = data.features
    .slice()
    .sort(({properties: {id: idA}}, {properties: {id: idB}}) => {
      return idA < idB ? -1 : idA > idB ? 1 : 0;
    });

  const alertId = sortedFeatures.find((feat) => feat.properties.alert)?.properties.id;

  return {
    ...roadProcessed,
    data: {
      ...data,
      features: sortedFeatures,
    },
    alertId,
  } as RoadProcessed;
};
