import {authentication} from '@onsmart/auth-client';
import axios from 'axios';

import type {AxiosInstance} from 'axios';
import type {DatagridMetadata} from 'models/DatagridMetadata';
interface Options {
  apiUrl: string;
  baseUrl: string;
  token?: string;
}

export abstract class RestService<T> {
  protected options: Options;
  protected axiosInstance: Readonly<AxiosInstance>;

  constructor(options: Options) {
    this.options = options;

    this.axiosInstance = axios.create({
      baseURL: options.baseUrl,
      headers: {
        Authorization: `Bearer ${options.token ?? authentication.getToken()}`,
      },
    });

    this.axiosInstance.interceptors.response.use(
      (resp) => resp,
      (err) => {
        const {response: {status} = {status: 0}} = err || {};
        if (status === 401) {
          const {store} = require('../config/redux/store');
          if (store) store.dispatch(authentication.actions.logout());
        }
        throw err;
      },
    );
  }

  /**
   * Create Model
   * @param {T} data - Model to be created
   * @returns {Promise<Model>} - Created Model
   */
  public create(data: Partial<T>): Promise<T> {
    if (!data) throw new Error('Parameter data is required');
    return this.axiosInstance.post<T>(this.options.apiUrl, data).then((r) => {
      return r.data;
    });
  }

  /**
   * Query Model from elasticsearch api by POST
   * @param elasticQuery - elasticsearch
   */
  public search<R = void>(elasticQuery: any, params?: any) {
    return this.axiosInstance
      .post<DatagridMetadata<R extends {} ? R : T>>(this.options.apiUrl + '/search', elasticQuery, {
        params,
      })
      .then((r) => {
        return r.data;
      });
  }

  /**
   * Query Model
   * @param {object} query - Query params
   * @returns {Promise<DatagridMetadata<T>>} - List of Models, total, limit and skip values
   */
  public find<R = void>(query: Object = {}) {
    return this.axiosInstance
      .get<DatagridMetadata<R extends {} ? R : T>>(this.options.apiUrl, query)
      .then((r) => {
        return r.data;
      });
  }

  /**
   * Query Model
   * @param {string} name - Name param
   * @returns {Promise<T>} - Found Model
   */
  public findOneByName<R = void>(name: string, params: any = {}) {
    if (!name) {
      throw new Error('Parameter name is required');
    }

    return this.find<R>({params: {$limit: 1, name, ...params}}).then(
      (response) => response.data[0],
    );
  }

  /**
   * Get Model by ID.
   * @returns {Promise<T>} - Found Model
   */
  public get(id: string, params?: any) {
    if (!id) throw new Error('Parameter id is required');

    return this.axiosInstance.get<T>(this.options.apiUrl + '/' + id, {params}).then((r) => {
      return r.data;
    });
  }

  /**
   * Update Model by ID
   * @param {string | number} id - Unique ID
   * @param {T} data - Model to be updated
   * @returns {Promise<Model>} - Updated Model
   */
  public update(id: string | number, data: Partial<T>) {
    if (!id) throw new Error('Parameter id is required');
    if (!data) throw new Error('Parameter data is required');

    return this.axiosInstance.patch<T>(this.options.apiUrl + '/' + id, data).then((r) => {
      return r.data;
    });
  }

  /**
   * Remove Model by ID
   * @param {string | number} id - Unique ID
   */
  public remove(id: string | number) {
    if (!id) throw new Error('Parameter id is required');

    return this.axiosInstance.delete(this.options.apiUrl + '/' + id).then((r) => {
      return r.data;
    });
  }
}
