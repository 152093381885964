import {CoreClient, gql} from '../graphql/CoreClient';

import type {Activity} from 'models/Activity';
import type {CreateActivityVariables} from './__generated__/CreateActivity';
import type {SearchActivitiesVariables} from './__generated__/SearchActivities';

const ACTIVITY = gql`
  fragment Activity on PanelCurationEvent {
    id
    type
    image
    summary
    published
    actor {
      name
      email
    }
    object {
      type
      url
      name
    }
  }
`;

const SEARCH_ACTIVITIES = gql`
  query SearchActivities(
    $limit: Int
    $operator: QueryOperator
    $query: String
    $skip: Int
    $sort: PanelsCurationEventsSort
  ) {
    searchPanelsCurationEvents(
      limit: $limit
      operator: $operator
      query: $query
      skip: $skip
      sort: $sort
    ) {
      total
      data {
        ...Activity
      }
    }
  }
  ${ACTIVITY}
`;

type SearchActivitiesResponse = {
  searchPanelsCurationEvents: {
    total: number;
    data: Activity[];
  };
};

export const searchActivities = async (variables: SearchActivitiesVariables) => {
  const {
    searchPanelsCurationEvents: response,
  } = await CoreClient.getInstance().request<SearchActivitiesResponse>(
    SEARCH_ACTIVITIES,
    variables,
  );
  return response;
};

const CREATE_ACTIVITY = gql`
  mutation CreateActivity(
    $type: PanelCurationEventType!
    $image: [String!]
    $object: PanelCurationEventObjectInput!
    $summary: String
  ) {
    createPanelCurationEvent(
      data: {type: $type, image: $image, object: $object, summary: $summary}
    ) {
      ...Activity
    }
  }
  ${ACTIVITY}
`;

type CreateActivityResponse = {
  createPanelCurationEvent: Activity;
};

export const createActivity = async (variables: CreateActivityVariables) => {
  const {
    createPanelCurationEvent: response,
  } = await CoreClient.getInstance().request<CreateActivityResponse>(CREATE_ACTIVITY, variables);
  return response;
};
