import {isEmpty} from 'lodash';
import {delay} from 'redux-saga';
import {call, put, select, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global/actions';
import {safe} from 'utils/sagas';

import {searchAssets} from '../../../../services/AssetRestService';
import {convertObjToElasticQueryString} from '../../../../utils/elasticSearch';
import {actions} from './actions';

import type {RootState} from 'config/redux/rootReducer';
import type {SagaReturnType} from '../../../../utils/sagas';
const FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY = {
  'media.category': 'mediaCategory',
  'media.subCategory': 'mediaSubCategory',
  'media.names': 'mediaNameSynonyms',
};

const search = (
  filters: RootState['inventory']['filters'],
  pagination: RootState['inventory']['pagination'],
) => {
  const {limit, skip, sort} = pagination;
  const {
    searchTerm,
    curationStatus,
    market: marketCode,
    dataOrigin,
    panelStatus,
    media,
    location,
    inventory: ids,
    acceptableImage: marketingImageAcceptable,
    prime,
    format,
  } = filters;

  const queryFilters = {
    ...(!isEmpty(location) && {[location.typeKey]: location.values}),
    curationStatus,
    marketCode,
    dataOrigin,
    panelStatus,
    _id: ids,
    marketingImageAcceptable,
    media,
    prime,
    format,
  };

  const query = convertObjToElasticQueryString({
    elasticKeyDictionary: FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY,
    obj: queryFilters,
    rawQueryString: searchTerm,
  });

  return searchAssets({
    limit,
    skip,
    sort,
    query,
  });
};

const fetchDataSaga = safe(function* () {
  //debounce
  yield call(delay, 500);
  yield put(globalActions.showLoading());

  const state: RootState['inventory'] = yield select<RootState>(({inventory}) => inventory);
  const {pagination, filters} = state;

  try {
    const result: SagaReturnType<typeof search> = yield call(search, filters, pagination);
    const {data, total} = result;

    yield put(actions.fetchDataSuccess({data, total}));
  } catch (err) {
    yield put(actions.fetchDataFail());
    yield put(globalActions.showMessage('💥 Unable to load assets'));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const inventoryPageSaga = function* () {
  yield takeLatest(
    [
      actions.fetchData,
      actions.setPageSort,
      actions.setPageLimit,
      actions.setPageSkip,
      actions.setFilters,
      actions.resetFilters,
    ],
    fetchDataSaga,
  );
};
