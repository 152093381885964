import {createAction} from 'redux-act';

import type {QuintileDataSetStatus} from '__generated__/globalTypes';

import type {InitialState} from './reducers';

export const actions = {
  setPageSort: createAction<InitialState['pagination']['sort']>('quintileDataSets/SET_SORT'),
  setPageLimit: createAction<InitialState['pagination']['limit']>(
    'quintileDataSets/SET_PAGE_LIMIT',
  ),
  setPageSkip: createAction<InitialState['pagination']['skip']>('quintileDataSets/SET_PAGE_SKIP'),
  setFilters: createAction<InitialState['filters']>('quintileDataSets/SET_FILTERS'),
  concatFilters: createAction<Partial<InitialState['filters']>>('quintileDataSets/CONCAT_FILTERS'),
  setQuintileDataSetStatus: createAction<QuintileDataSetStatus[]>('quintileDataSets/SET_STATUS'),
  resetFilters: createAction('quintileDataSets/RESET_FILTERS'),
};
