import {push} from 'react-router-redux';
import {MarketsSortOption} from '__generated__/globalTypes';
import {call, put, takeLatest} from 'redux-saga/effects';

import {searchMarkets} from 'services/MarketService';

import {actions} from './actions';

import type {Action} from 'redux-act';
import type {SagaReturnType} from '../../../utils/sagas';
const fetchMarketsData = (includeInactiveMarkets: boolean) => {
  return searchMarkets({
    limit: 200,
    sort: MarketsSortOption.NAME_ASC,
    query: `${!includeInactiveMarkets ? 'active:true' : '*'}`,
  });
};

function* fetchMarketsSaga(action: Action<boolean>) {
  const includeInactiveMarkets = action.payload;

  yield put(actions.setIncludeInactiveMarkets(includeInactiveMarkets));

  try {
    const {searchMarkets}: SagaReturnType<typeof fetchMarketsData> = yield call(
      fetchMarketsData,
      includeInactiveMarkets,
    );
    yield put(actions.setMarkets(searchMarkets.data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllMarketsSaga() {
  try {
    const {searchMarkets}: SagaReturnType<typeof fetchMarketsData> = yield call(
      fetchMarketsData,
      true,
    );
    yield put(actions.setAllMarkets(searchMarkets.data));
  } catch (e) {
    console.error(e);
  }
}

function* navigateToRoute(action: Action<string>) {
  const path = action.payload;
  yield put(push(path));
}

export function* appSaga() {
  yield takeLatest(actions.navigateToRoute, navigateToRoute);
  yield takeLatest(actions.fetchMarkets, fetchMarketsSaga);
  yield takeLatest(actions.fetchAllMarkets, fetchAllMarketsSaga);
}
